import React from "react";
import style from "./inventorylistitem.module.scss";
import { useSelector } from "react-redux";
import { EditIcon } from "../../assets/icons";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";
function InventoryListItem({
  selectedInventory,
  inventoryData,
  setInventoryListData,
}) {
  const reduxChecked = useSelector(
    (state) => state.checkedList.inventoryChecked
  );
  return (
    <>
      {inventoryData
        ? inventoryData.map((item) => {
            return (
              <Link
                key={item.id}
                className={style.leadListItem}
                to={`/inventory-detail/${item.id}`}
              >
                <input
                  style={{ zIndex: 20, margin: "0 19px", cursor: "pointer" }}
                  type="checkbox"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    let value = e.target.checked;
                    setInventoryListData(
                      inventoryData.map((sd) => {
                        if (sd.id === item.id) {
                          sd.select = value;
                        }
                        return sd;
                      })
                    );
                  }}
                  checked={item.select}
                />
                {selectedInventory?.includes("Proje İsmi") &&
                reduxChecked?.includes("Proje İsmi") ? (
                  <div style={{ flex: 3, textAlign: "center" }}>
                    {item?.ownerProject?.projectName}
                  </div>
                ) : null}
                {selectedInventory.includes("Ünite İsmi") &&
                reduxChecked.includes("Ünite İsmi") ? (
                  <div style={{ flex: 3, textAlign: "center" }}>
                    {item.inventoryName}
                  </div>
                ) : null}
                {selectedInventory.includes("Ürün Tipi") &&
                reduxChecked.includes("Ürün Tipi") ? (
                  <div style={{ flex: 3, textAlign: "center" }}>
                    {item.inventoryCategory === "satılık"
                      ? "Satılık"
                      : item.inventoryCategory === "kiralık"
                      ? "Kiralık"
                      : item.inventoryCategory === "satılıkveyakiralık"
                      ? "Satılık veya Kiralık"
                      : null}
                  </div>
                ) : null}
                {selectedInventory.includes("Statü") &&
                reduxChecked.includes("Statü") ? (
                  <div style={{ flex: 3, textAlign: "center" }}>
                    {item.status}
                  </div>
                ) : null}
                {selectedInventory.includes("İlçe") &&
                reduxChecked.includes("İlçe") ? (
                  <div style={{ flex: 3, textAlign: "center" }}>
                    {item.city}
                  </div>
                ) : null}
                {selectedInventory.includes("Şehir") &&
                reduxChecked.includes("Şehir") ? (
                  <div style={{ flex: 3, textAlign: "center" }}>
                    {item.country}
                  </div>
                ) : null}
                {selectedInventory.includes("Brüt Metrekare") &&
                reduxChecked.includes("Brüt Metrekare") ? (
                  <div style={{ flex: 3, textAlign: "center" }}>
                    {item.brutM2} m²
                  </div>
                ) : null}
                {selectedInventory.includes("KDV Hariç Liste Fiyatı") &&
                reduxChecked.includes("KDV Hariç Liste Fiyatı") ? (
                  <div style={{ flex: 3, textAlign: "center" }}>
                    {
                      <NumericFormat
                        style={{
                          width: "70%",
                          color: "#333e48",
                        }}
                        value={
                          item.excludingVatListPrice === 0
                            ? "-"
                            : item.excludingVatListPrice
                        }
                        isNumericString
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        suffix={" ₺"}
                      />
                    }
                  </div>
                ) : null}
                {selectedInventory.includes("Kira Bedeli") &&
                reduxChecked.includes("Kira Bedeli") ? (
                  <div style={{ flex: 3, textAlign: "center" }}>
                    {
                      <NumericFormat
                        style={{
                          width: "70%",
                          color: "#333e48",
                          textAlign: "center",
                          backgroundColor: "transparent",
                        }}
                        value={item.rentPrice === 0 ? "-" : item.rentPrice}
                        isNumericString
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        suffix={" ₺"}
                        disabled
                      />
                    }
                  </div>
                ) : null}
                {selectedInventory.includes("Kayıt Tarihi") &&
                reduxChecked.includes("Kayıt Tarihi") ? (
                  <div style={{ flex: 3, textAlign: "center" }}>
                    {item.createAtReal}
                  </div>
                ) : null}
                {selectedInventory.includes("Net Metrekare") &&
                reduxChecked.includes("Net Metrekare") ? (
                  <div style={{ flex: 3, textAlign: "center" }}>
                    {item.netM2}
                  </div>
                ) : null}
                {selectedInventory.includes("Bahçe Metrekare") &&
                reduxChecked.includes("Bahçe Metrekare") ? (
                  <div style={{ flex: 3, textAlign: "center" }}>
                    {item.gardenM2}
                  </div>
                ) : null}
                {selectedInventory.includes("Havuz Metrekare") &&
                reduxChecked.includes("Havuz Metrekare") ? (
                  <div style={{ flex: 3, textAlign: "center" }}>
                    {item.poolM2}
                  </div>
                ) : null}
                {selectedInventory.includes("Teras Metrekare") &&
                reduxChecked.includes("Teras Metrekare") ? (
                  <div style={{ flex: 3, textAlign: "center" }}>
                    {item.terraceM2}
                  </div>
                ) : null}
                {selectedInventory.includes("Balkon Metrekare") &&
                reduxChecked.includes("Balkon Metrekare") ? (
                  <div style={{ flex: 3, textAlign: "center" }}>
                    {item.balconyM2}
                  </div>
                ) : null}
                {selectedInventory.includes("Metrekare Birim Satış Bedeli") &&
                reduxChecked.includes("Metrekare Birim Satış Bedeli") ? (
                  <div style={{ flex: 3, textAlign: "center" }}>
                    {item.salesPriceM2}
                  </div>
                ) : null}
                {selectedInventory.includes("Metrekare Birim Kira Bedeli") &&
                reduxChecked.includes("Metrekare Birim Kira Bedeli") ? (
                  <div style={{ flex: 3, textAlign: "center" }}>
                    {item.salesRentM2}
                  </div>
                ) : null}

                {selectedInventory?.includes("Mal Sahibi") &&
                reduxChecked?.includes("Mal Sahibi") ? (
                  <div style={{ flex: 3, textAlign: "center" }}>
                    {item?.inventoryOwnerId?.name}{" "}
                    {item?.inventoryOwnerId?.surname}
                  </div>
                ) : null}
                <div
                  style={{
                    flex: 3,
                    textAlign: "center",
                    textDecoration: "none",
                  }}
                >
                  <Link
                    to={`/inventory-list-edit/${item.id}`}
                    style={{ borderBottom: "none" }}
                  >
                    <EditIcon />
                  </Link>
                </div>
              </Link>
            );
          })
        : null}
    </>
  );
}

export default InventoryListItem;
