import React from "react";
import style from "./ApprovalDetail.module.scss";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { SubHeader, SideNav } from "../../components";
import { NumericFormat } from "react-number-format";
import { BaseUrl } from "../../ApiConfig";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

function ApprovalDetail() {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  const { meetingShapeTR, meetingShapeEN } = useSelector(
    (state) => state.system.meetingShape
  );
  const { bankCreditTR, bankCreditEN } = useSelector(
    (state) => state.system.bankCredit
  );
  const { bidStageTR, bidStageEN } = useSelector(
    (state) => state.system.bidStage
  );
  const { failedStageNoteTR, failedStageNoteEN } = useSelector(
    (state) => state.system.failedStageNote
  );
  const navigate = useNavigate();
  const { id, contactId } = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    axios
      .get(`${BaseUrl}/api/approval/findOne/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setData(res.data.bid);
      })
      .catch((err) => {
        console.log(err.response);
        navigate("/approval-list", { replace: true });
      });
  }, [id, user.token, navigate]);

  const currencyConvert = (value) => {
    if (value === "TRY") {
      return "₺";
    } else if (value === "USD") {
      return "$";
    } else if (value === "EUR") {
      return "€";
    } else if (value === "GBP") {
      return "£";
    } else if (value === "RUB") {
      return "₽";
    } else {
      return "₺";
    }
  };

  const currencyFormat = (value) => {
    var currency_symbol = currencyConvert(data.currency);

    var formattedOutput = new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: data?.currency || "TRY",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return value.length
      ? formattedOutput.format(value).replace(currency_symbol, "")
      : "";
  };
  const dateFormat = (date) => {
    let newDate = new Date(date);
    let newDateReal =
      newDate.getDate() +
      "-" +
      (newDate.getMonth() + 1) +
      "-" +
      newDate.getFullYear();
    return date ? newDateReal : "";
  };
  const paymentTypeConverter = (paymentType) => {
    if (paymentType === "Tamamı Peşin") {
      return t("tamamiPesin");
    } else if (paymentType === "Kredili") {
      return t("kredili");
    } else if (paymentType === "Kredili + Vadeli") {
      return t("krediliVadeli");
    } else if (paymentType === "Vadeli") {
      return t("vadeli");
    }
  };
  return (
    <div className={style.approvalDetail}>
      <SubHeader
        bidDetaillData={data}
        bidDetail
        bidBackHref={`/contact-detail/${contactId}`}
      />
      <div className={style.content}>
        <SideNav bidDetail="1" />
        <div className={style.info}>
          <div className={style.content2}>
            {data ? (
              <>
                <div className={style.column}>
                  {user.department === "admin" && (
                    <div className={style.cards}>
                      <h2>ID</h2>
                      <p>{data._id ? data._id : ""} </p>
                    </div>
                  )}
                  <div className={style.cards}>
                    <h2>{t("olusturanPersonel")}</h2>
                    <h1>{data.ownerBid ? data.ownerBid : ""} </h1>
                  </div>
                  <div className={style.cards}>
                    <h2>{t("musteriAdi")}</h2>
                    <Link
                      to={`/contact-detail/${
                        data.contactId ? data?.contactId?._id : ""
                      }`}
                    >
                      {data.contactId ? data.contactId.name : ""}{" "}
                      {data.contactId ? data.contactId.surname : ""}
                    </Link>
                  </div>
                  <div className={style.cards}>
                    <h2>{t("aktiviteTipi")}</h2>
                    <h1>
                      {data.bidShape
                        ? data.bidShape === "Satış"
                          ? t("satisDepertmani")
                          : data.bidShape === "Kiralama"
                          ? t("kiralamaDepartmani")
                          : null
                        : ""}{" "}
                    </h1>
                  </div>
                  <div className={style.cards}>
                    <h2>{t("aktiviteIsmi")}</h2>
                    <h1>{data.bidName ? data.bidName : ""} </h1>
                  </div>
                  <div className={style.cards}>
                    <h2>{t("gorusmeKanali")}</h2>
                    <h1>
                      {data.meetingShape
                        ? i18n.language === "tr"
                          ? meetingShapeTR.find(
                              (item) => item.value === data.meetingShape
                            )?.name
                          : i18n.language === "en"
                          ? meetingShapeEN.find(
                              (item) => item.value === data.meetingShape
                            )?.name
                          : null
                        : ""}
                    </h1>
                  </div>
                  <div className={style.cards}>
                    <h2>{t("iliskiliProje")}</h2>
                    <Link
                      to={`/project-detail/${
                        data.projectId ? data.projectId : ""
                      }`}
                    >
                      {data.projeName ? data.projeName : ""}
                    </Link>
                  </div>
                  <div className={style.cards}>
                    <h2>{t("urunTipi")}</h2>
                    <Link
                      to={
                        data?.inventoryId
                          ? `/inventory-detail/${data?.inventoryId?._id}`
                          : ""
                      }
                    >
                      {data.inventoryName ? data.inventoryName : ""}
                    </Link>
                  </div>
                  <div className={style.cards}>
                    <h2>{t("urunTipi")}</h2>
                    <h1>
                      {data.inventoryId.corporateStatus
                        ? data.inventoryId.corporateStatus === "konut"
                          ? t("konut")
                          : data.inventoryId.corporateStatus === "dukkan"
                          ? t("dukkan")
                          : data.inventoryId.corporateStatus === "ofis"
                          ? t("ofis")
                          : data.inventoryId.corporateStatus === "atolye"
                          ? t("atolye")
                          : data.inventoryId.corporateStatus === "villa"
                          ? t("villa")
                          : null
                        : ""}{" "}
                    </h1>
                  </div>
                  {data.bidShape === "Satış" ? (
                    <>
                      <div className={style.cards}>
                        <h2>{t("kdvHaricSatisFiyat")}</h2>
                        <h1>
                          <NumericFormat
                            style={{
                              background: "transparent",
                              width: "100%",
                            }}
                            value={data.bidAmount ? data.bidAmount : "-"}
                            isNumericString
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            suffix={currencyConvert(data.currency)}
                            disabled
                          />
                        </h1>
                      </div>
                      {data.bidAmountWithVat ? (
                        <div className={style.cards}>
                          <h2>{t("kdv")}</h2>
                          <h1>
                            <NumericFormat
                              style={{
                                color: "#333e48",
                                background: "transparent",
                                width: "100%",
                              }}
                              value={
                                data.bidAmountWithVat - data.bidAmount
                                  ? data.bidAmountWithVat - data.bidAmount
                                  : ""
                              }
                              disabled
                              isNumericString
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              suffix={currencyConvert(data.currency)}
                            />
                          </h1>
                        </div>
                      ) : data.discountRate ? (
                        <div className={style.cards}>
                          <h2>{t("indirimOrani")}</h2>
                          <h1>%{data.discountRate ? data.discountRate : ""}</h1>
                        </div>
                      ) : data.advancePayment ? (
                        <div className={style.cards}>
                          <h2>{t("kapora")}</h2>
                          <h1>
                            {currencyFormat(data.advancePayment)}
                            {currencyConvert(data.currency)}
                            {data.advancePaymentDate
                              ? "/" + dateFormat(data.advancePaymentDate)
                              : ""}
                          </h1>
                        </div>
                      ) : data.downPaymnet ? (
                        <div
                          className={style.cards}
                          style={{ marginTop: "1rem" }}
                        >
                          <h2>{t("pesinat")}</h2>
                          <h1>
                            {currencyFormat(data.downPaymnet)}
                            {currencyConvert(data.currency)}
                            {data.downPaymnetDate
                              ? "/" + dateFormat(data.downPaymnetDate)
                              : ""}
                          </h1>
                        </div>
                      ) : data.termDownPaymnet ? (
                        <div
                          className={style.cards}
                          style={{ marginTop: "1rem" }}
                        >
                          <h2>{t("vadelendirilecekTutar")}</h2>
                          <h1>
                            {currencyFormat(data.termDownPaymnet)}
                            {currencyConvert(data.currency)}
                            {data.termDownPaymnetDate
                              ? "/" + dateFormat(data.downPaymnetDate)
                              : ""}
                          </h1>
                        </div>
                      ) : data.bankCredit ? (
                        <div
                          className={style.cards}
                          style={{ marginTop: "1rem" }}
                        >
                          <h2>{t("bankaKredisi")}</h2>
                          <h1>
                            {currencyFormat(data.bankCredit)}
                            {currencyConvert(data.currency)}
                            {data.bankCreditDate
                              ? "/" + dateFormat(data.bankCreditDate)
                              : ""}
                          </h1>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>

                <div className={style.column}>
                  {data.bidShape === "Satış" ? (
                    <>
                      {data.bankToBeUsed !== "" ? (
                        <div className={style.cards}>
                          <h2>{t("krediKullanilanBanka")}</h2>
                          <h1>
                            {data.bankToBeUsed
                              ? i18n.language === "tr"
                                ? bankCreditTR.find(
                                    (item) => item.value === data.bankToBeUsed
                                  )?.name
                                : i18n.language === "en"
                                ? bankCreditEN.find(
                                    (item) => item.value === data.bankToBeUsed
                                  )?.name
                                : null
                              : "-"}
                          </h1>
                        </div>
                      ) : data.billAmount.toString() !== "" ? (
                        <>
                          <div className={style.cards}>
                            <h2>{t("senetTutari")}</h2>
                            <h1>
                              {currencyFormat(data.billAmount.toString())}
                              {currencyConvert(data.currency)}
                            </h1>
                          </div>
                          <div className={style.cards}>
                            <h2>{t("senetAdedi")}</h2>
                            <h1>{data.billLength ? data.billLength : ""}</h1>
                          </div>
                        </>
                      ) : data.otherExpenses.toString() !== "" ? (
                        <div className={style.cards}>
                          <h2>{t("teslimeIliskinDigerMasraf")}</h2>
                          <h1>
                            {data.otherExpenses
                              ? currencyFormat(data.otherExpenses)
                              : "-"}
                          </h1>
                        </div>
                      ) : data.interestAmount ? (
                        <div className={style.cards}>
                          <h2>{t("faizMiktariYillikOran")}</h2>
                          <h1>
                            {data.interestAmount ? data.interestAmount : "-"}
                          </h1>
                        </div>
                      ) : data.deliveryTime ? (
                        <div className={style.cards}>
                          <h2>{t("teslimTarihi")}</h2>
                          <h1>
                            {data.deliveryTime
                              ? dateFormat(data.deliveryTime)
                              : "-"}
                          </h1>
                        </div>
                      ) : data.paymentType ? (
                        <div className={style.cards}>
                          <h2>{t("odemeTipi")}</h2>
                          <h1>
                            {data.paymentType
                              ? paymentTypeConverter(data.paymentType)
                              : "-"}
                          </h1>
                        </div>
                      ) : data.paymentPlanFileName ? (
                        <div className={style.cards}>
                          <h2>{t("odemePlani")}</h2>
                          <Link
                            // onClick={() => dowloadFile(data.paymentPlanFileName)}
                            to={`${BaseUrl}/api/bid/paymentPlanDowload/${data.paymentPlanFileName}`}
                          >
                            {data.paymentPlanFileName
                              ? data.paymentPlanFileName
                              : "-"}
                          </Link>
                        </div>
                      ) : null}
                    </>
                  ) : null}

                  <div className={style.cards}>
                    <h2>{t("aktiviteAsamasi")}</h2>
                    <h1>
                      {data.bidStage
                        ? data.bidStage === "Sunum"
                          ? t("sunum")
                          : i18n.language === "tr"
                          ? bidStageTR.find(
                              (item) => item.value === data.bidStage
                            )?.name
                          : i18n.language === "en"
                          ? bidStageEN.find(
                              (item) => item.value === data.bidStage
                            )?.name
                          : null
                        : "-"}
                    </h1>
                  </div>
                  {data.failedStageNote ? (
                    <div>
                      <div className={style.cards}>
                        <h2>{t("basarisizKapanisNedeni")}</h2>
                        <h1>
                          {data.failedStageNote
                            ? i18n.language === "tr"
                              ? failedStageNoteTR.find(
                                  (item) => item.value === data.failedStageNote
                                )?.name
                              : i18n.language === "en"
                              ? failedStageNoteEN.find(
                                  (item) => item.value === data.failedStageNote
                                )?.name
                              : "-"
                            : "-"}
                        </h1>
                      </div>

                      <div className={style.cards}>
                        <h2>{t("kapanisTarihi")}</h2>
                        <h1>{data.closingDate ? data.closingDate : "-"}</h1>
                      </div>
                    </div>
                  ) : null}
                  <div
                    className={style.cards}
                    style={{
                      height: "auto",
                      maxWidth: "450px",
                      paddingTop: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <h2>{t("not")}</h2>
                    <h1>{data.bidNote ? data.bidNote : "-"}</h1>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApprovalDetail;
