import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import App from "./App";

import "./i18n";
import "./assets/style/index.scss";

// Artık bu importlara ve history'e ihtiyacımız yok
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { createBrowserHistory } from "history";
// window.process = {};
// export const history = createBrowserHistory();

const root = createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/*",
    element: <App />,
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider
        router={router}
        future={{
          v7_startTransition: true,
          v7_relativeSplatPath: true,
        }}
      />
    </Provider>
  </React.StrictMode>
);
/*
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter history={history}>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
*/
