import { SubHeader } from "../../components";
import { useSelector } from "react-redux";
import Dashboard from "./Dashboard";
import Welcome from "./Welcome";

const Home = () => {
  const user = useSelector((state) => state.user.userData);
  const department = user.department;
  const allows = ["admin", "ceo", "dsb"];
  const isAllow = allows.includes(department);
  return (
    <div>
      <SubHeader homeMenu />
      {isAllow ? <Dashboard /> : <Welcome />}
    </div>
  );
};
export default Home;
