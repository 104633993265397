import React, { useState, useEffect } from "react";
import { getAllUsersForLog } from "../../../lib/userApi";
import { DateInput } from "../../../components/Forms/Inputs";
import { useSelector } from "react-redux";
import { BaseUrl } from "../../../ApiConfig";
import axios from "axios";
import fileDownload from "js-file-download";
import { extractIdsFromObject } from "../../../utils/general";
import LogTypeSelection from "../../../components/ui/logTypeSelection/LogTypeSelection";
import UserSelection from "../../../components/ui/userSelection/UserSelection";
import { Tooltip } from "react-tooltip";
import style from "./rapors.module.scss";

function LogRaporu() {
  const user = useSelector((state) => state.user.userData);
  const allTypes = [
    { name: "Genel Müşteri Analizi Log Raporu", value: "GMA" },
    { name: "Genel Satış Log Raporu", value: "GS" },
    { name: "Kaynak Bazlı İletişim Log Raporu", value: "KBI" },
    { name: "Günlük Log Rapor", value: "GR" },
    { name: "İletişim Tip Log Raporu", value: "ITR" },
    { name: "İptal Log Raporu", value: "IR" },
    { name: "Satış, Kapara, Opsiyon Log Raporu", value: "SKOR" },
    { name: "Personel Performan Log Raporu", value: "PPR" },
    { name: "Ürün Tipi Stok Log Raporu", value: "UTSR" },
    { name: "Aktivite Log Raporu", value: "Bid" },
    { name: "Kontak Log Raporu", value: "Contact" },
    { name: "Ünite Log Raporu", value: "Inventory" },
    { name: "Not Log Raporu", value: "Note" },
  ];

  const [allUsers, setAllUsers] = useState([]);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
  const [checkedUserCheckboxes, setUserCheckedCheckboxes] = useState([]);

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [rapor, setRapor] = useState({
    selectedTypes: [],
    selectedUsers: [],
    raporType: "logs",
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    getAllUsersForLog(user.token)
      .then((res) => {
        setAllUsers(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user.token]);

  const buttonClickHandler = () => {
    setError(false);
    if (rapor.startDate === "" || rapor.endDate === "") {
      setError(true);
      setErrorText("Tarih alanları boş bırakılamaz");
      setTimeout(() => {
        setError(false);
        setErrorText("");
      }, 3000);
      return;
    }
    if (rapor.startDate > rapor.endDate) {
      setError(true);
      setErrorText("Başlangıç tarihi bitiş tarihinden büyük olamaz");
      setTimeout(() => {
        setError(false);
        setErrorText("");
      }, 3000);
      return;
    }
    rapor.selectedTypes =
      checkedCheckboxes.length > 0
        ? checkedCheckboxes
        : extractIdsFromObject(allTypes, "value");
    rapor.selectedUsers =
      checkedUserCheckboxes.length > 0
        ? checkedUserCheckboxes
        : extractIdsFromObject(allUsers);
    axios({
      url: `${BaseUrl}/api/rapor/get-rapor`,
      method: "POST",
      responseType: "blob",
      data: {
        rapor: rapor,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((response) => {
        fileDownload(
          response.data,
          `Log Raporu - ${new Date().toLocaleString()}.xlsx`
        );
      })
      .catch(async (err) => {
        if (err.response.status === 400) {
          setError(true);
          const reader = new FileReader();
          reader.onload = function () {
            const errorMessage = reader.result;
            setErrorText(JSON.parse(errorMessage)?.message);
          };
          reader.readAsText(err.response.data);
        }
      });
  };

  return (
    <div className={style.container}>
      {error && <div className={style.errorText}>{errorText}</div>}
      <Tooltip id="project" aria-haspopup="true">
        Proje seçimini boş bıraktığınızda <br />
        bütün projerler seçilecektir.
      </Tooltip>
      <LogTypeSelection
        props={{
          "data-tip": "data-tip",
          "data-for": "type",
        }}
        title="Log Tipi Seçiniz"
        placeholder={
          (checkedCheckboxes.length > 0
            ? `(${checkedCheckboxes.length}) `
            : "") + " Log Tipi Seçiniz"
        }
        types={allTypes}
        setCheckedCheckboxes={setCheckedCheckboxes}
        checkedCheckboxes={checkedCheckboxes}
      />
      <UserSelection
        props={{
          "data-tip": "data-tip",
          "data-for": "users",
        }}
        title="Kullanıcı Seçiniz"
        placeholder={
          (checkedUserCheckboxes.length > 0
            ? `(${checkedUserCheckboxes.length}) `
            : "") + " Kullanıcı Seçiniz"
        }
        allUsers={allUsers}
        setCheckedUserCheckboxes={setUserCheckedCheckboxes}
        checkedUserCheckboxes={checkedUserCheckboxes}
      />
      <DateInput
        title={"Başlangıç Tarihi"}
        value={rapor.startDate}
        onChange={(text) =>
          setRapor({
            ...rapor,
            startDate: text,
          })
        }
      />
      <DateInput
        title={"Bitiş Tarihi"}
        value={rapor.endDate}
        onChange={(text) =>
          setRapor({
            ...rapor,
            endDate: text,
          })
        }
      />
      <button className={style.button} onClick={buttonClickHandler}>
        Log Raporu
      </button>
    </div>
  );
}

export default LogRaporu;
