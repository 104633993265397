import React, { useRef, useEffect } from "react";
import style from "./Table.module.scss";
import { Tooltip } from "react-tooltip";
import { DownIcon, UpIcon } from "../../../assets/icons/index";
import { useTranslation } from "react-i18next";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Table({
  getTableBodyProps,
  page,
  prepareRow,
  gotoPage,
  canPreviousPage,
  previousPage,
  nextPage,
  canNextPage,
  pageCount,
  setPageSize,
  pageIndex,
  pageOptions,
  pageSize,
  headerGroups,
  getTableProps,
  isLoading,
}) {
  const { t } = useTranslation();

  const tableRef = useRef(null);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [pageIndex]);

  return (
    <div className={style.tableContainer}>
      {isLoading ? (
        <Skeleton
          count={15}
          style={{
            height: 70,
            marginTop: 8,
            marginBottom: 4,
            width: "100%",
            marginLeft: 10,
            marginRight: 10,
          }}
        />
      ) : (
        <table {...getTableProps()} ref={tableRef}>
          <thead className={style.titleContainer}>
            {headerGroups.map((headerGroup) => (
              <tr
                className={style.titleContainer}
                style={{ width: "100%" }}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    style={{
                      flex:
                        column.id === "selection"
                          ? 0.5
                          : column.id === "actions"
                          ? 3
                          : 2,
                    }}
                    {...column.getHeaderProps()}
                  >
                    <label {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <UpIcon />
                        ) : (
                          <DownIcon />
                        )
                      ) : (
                        ""
                      )}
                    </label>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className={style.tableBody} {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr className={style.tableListItem} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        style={{
                          flex:
                            cell.column.id === "selection"
                              ? 0.5
                              : cell.column.id === "actions"
                              ? 3
                              : 2,
                        }}
                        {...cell.getCellProps()}
                      >
                        {cell.column.id === "contactStatus" &&
                          (cell.row.original.contactStatus === "aktif" ? (
                            <label className={style.active}>
                              {cell.render("Cell")}
                            </label>
                          ) : cell.row.original.contactStatus === "pasif" ? (
                            <label
                              data-tip
                              data-for="global"
                              className={style.passive}
                            >
                              {cell.render("Cell")}
                              <Tooltip id="global" aria-haspopup="true">
                                {t("kontakPasifText")}
                              </Tooltip>
                            </label>
                          ) : null)}
                        {cell.column.id === "contactStatus"
                          ? null
                          : cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <div className={style.pagination}>
        <button
          className={style.button}
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          style={{
            backgroundColor: !canPreviousPage && "#ccc",
            cursor: !canPreviousPage && "not-allowed",
            opacity: !canPreviousPage && "0.5",
          }}
        >
          {"<<"}
        </button>{" "}
        <button
          className={style.button}
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          style={{
            backgroundColor: !canPreviousPage && "#ccc",
            cursor: !canPreviousPage && "not-allowed",
            opacity: !canPreviousPage && "0.5",
          }}
        >
          {"<"}
        </button>{" "}
        <button
          className={style.button}
          style={{
            backgroundColor: !canNextPage && "#ccc",
            cursor: !canNextPage && "not-allowed",
            opacity: !canNextPage && "0.5",
          }}
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {">"}
        </button>{" "}
        <button
          className={style.button}
          style={{
            backgroundColor: !canNextPage && "#ccc",
            cursor: !canNextPage && "not-allowed",
            opacity: !canNextPage && "0.5",
          }}
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </button>{" "}
        <span>
          {t("sayfa")}{" "}
          <strong>
            {pageIndex + 1} - {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          {t("sayfayaGit")}{" "}
          <input
            className={style.input}
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </span>
        {t("sayfayaBasinaGosterim")}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[25, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default Table;
