import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import routes from "./routes/routes";
import { Login, ResetPassword } from "./containers";
import { jwtDecode } from "jwt-decode";
import Layout from "./Layout/Layout";
import RequireAuth from "./containers/Auth/RequireAuth";
import { checkUserThunk } from "./redux/slice/userSlice";
import { getDropDownThunk } from "./redux/slice/systemSlice";

let firstLoad = false;
let resetPassword = false;

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userData);

  const token = localStorage.getItem("AuthToken");
  useEffect(() => {
    if (!token && location.pathname === "/reset-password") {
      navigate("/reset-password", { replace: true });
      resetPassword = true;
      firstLoad = false;
    } else if (!token) {
      navigate("/login", { replace: true });
      resetPassword = false;
      firstLoad = false;
    } else if (token && location.pathname === "/login") {
      navigate("/", { replace: true });
      resetPassword = false;
      firstLoad = false;
    }
  }, [dispatch, navigate, location.pathname, token]);

  if (token && user.id === "" && !firstLoad) {
    const decoded = jwtDecode(token);
    if (decoded.exp * 1000 < Date.now()) {
      localStorage.removeItem("AuthToken");
      navigate("/login", { replace: true });
      firstLoad = false;
    } else {
      firstLoad = true;
      dispatch(checkUserThunk(token));
      dispatch(getDropDownThunk(token));
      firstLoad = false;
    }
  }

  if (resetPassword) {
    return (
      <Routes Layout>
        <Route key="first" path="/" element={<Layout />}>
          <Route
            key="reset-password"
            path="reset-password"
            element={<ResetPassword />}
          />
          {routes.map((route, index) => (
            <Route
              key="allowedRoles"
              element={<RequireAuth allowedRoles={route.allowedRoles} />}
            >
              <Route
                key={index}
                path={route.path}
                exact={true}
                element={<route.component />}
              />
            </Route>
          ))}
          <Route
            key="last"
            path="*"
            element={<Navigate to="/" replace={true} />}
          />
        </Route>
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route key="first" path="/" element={<Layout />}>
          <Route key="login" path="login" element={<Login />} />
          {routes.map((route, index) => (
            <Route
              key="allowedRoles"
              element={<RequireAuth allowedRoles={route.allowedRoles} />}
            >
              <Route
                key={index}
                path={route.path}
                exact={true}
                element={<route.component />}
              />
            </Route>
          ))}
          <Route
            key="last"
            path="*"
            element={<Navigate to="/" replace={true} />}
          />
        </Route>
      </Routes>
    );
  }
};

//const AppWithRouter = withRouter(App);

export default App;
