import { SubHeader, SideFilter } from "../../components";
import style from "./leadlist.module.scss";
import { useEffect, useState, useMemo } from "react";
import IndeterminateCheckbox from "../../components/table/indeterminateCheckbox/IndeterminateCheckbox";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import { useSelector } from "react-redux";
import { getAllLeads, deleteALead, sendLeadToContact } from "../../lib/leadApi";
import Moment from "moment";
import { Link } from "react-router-dom";
import Table from "../../components/table/customTable/Table";
import { Tooltip } from "react-tooltip";
import LeadPopUp from "./LeadPopUp";
import { useTranslation } from "react-i18next";
import {
  DeleteIcon,
  EditIcon,
  ThumbIcon,
  ViewIcon,
} from "../../assets/icons/index";

const LeadList = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [hasFilterData, setHasFilterData] = useState(false);
  const [pagination, setPagination] = useState({
    queryPageIndex: 0,
    queryPageSize: 25,
    totalCount: null,
  });
  const [method, setNewMethod] = useState();
  const [body, setNewBody] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.user.userData);
  const [targetUser, setTargetUser] = useState({
    fullName: "",
    id: "",
  });
  const [targetProject, setTargetProject] = useState([]);
  const [sendOwner, setSendOwner] = useState({
    ownerName: "",
    ownerId: "",
  });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [modalType, setModalType] = useState("");

  const columns = useMemo(
    () => [
      {
        id: "selection",
        disableFilters: true,
        groupByBoundary: true,
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <IndeterminateCheckbox
            isindeterminate={
              getToggleAllRowsSelectedProps().indeterminate ? "true" : "false"
            }
            ischecked={
              getToggleAllRowsSelectedProps().checked ? "true" : "false"
            }
            {...getToggleAllRowsSelectedProps()}
          />
        ),
        Cell: ({ row }) => (
          <IndeterminateCheckbox
            ischecked={row.isSelected ? "true" : "false"}
            {...row.getToggleRowSelectedProps()}
          />
        ),
      },
      {
        Header: t("isim"),
        accessor: "name",
      },
      {
        Header: t("soyisim"),
        accessor: "surname",
      },
      {
        Header: t("projeAdi"),
        accessor: (d) => {
          let projects = "";
          for (const project of d?.associatedProject) {
            projects += project.projectName + ", ";
          }
          projects = projects.slice(0, -2);
          return projects;
        },
      },
      {
        Header: t("telefon"),
        accessor: (d) => {
          const template =
            d?.phone?.length === 12
              ? /(\d{2})(\d{3})(\d{3})(\d{4})/
              : /(\d{3})(\d{3})(\d{3})(\d{4})/;
          const phone = d?.phone?.toString().replace(template, "+$1($2) $3-$4");
          return phone;
        },
      },
      {
        Header: t("email"),
        accessor: (d) => {
          return d.email.length > 10
            ? d.email.substring(0, 10) + "..."
            : d.email;
        },
      },
      {
        Header: t("sirket"),
        accessor: "company",
      },
      {
        Header: t("olusturulmaTarihi"),
        accessor: (row) =>
          Moment(row.creationDate).local().format("DD.MM.YYYY"),
      },
      {
        Header: t("kanal"),
        accessor: "channel",
      },
      {
        Header: t("kullanciTipi"),
        accessor: "userType",
      },
      {
        Header: t("not"),
        id: "note",
        accessor: (row) =>
          row.note.length > 10 ? row.note.substring(0, 25) + "..." : row.note,
      },
      {
        Header: t("islemler"),
        accessor: "actions",
        Cell: ({ row }) => (
          <div className={style.icons}>
            <Tooltip id="view" type="warning" place="bottom" effect="solid">
              {t("iliskiliGoruntule")}
            </Tooltip>
            <Tooltip id="send" type="warning" place="bottom" effect="solid">
              {t("iliskiyiKontaklaraGonder")}
            </Tooltip>
            <Tooltip id="edit" type="warning" place="bottom" effect="solid">
              {t("iliskiyiDuzenle")}
            </Tooltip>
            <Tooltip id="delete" type="warning" place="bottom" effect="solid">
              {t("iliskiyiSil")}
            </Tooltip>
            <Link
              data-tip
              data-for="view"
              className={style.icon}
              to={`/lead-detail/${row.original._id}`}
            >
              <ViewIcon />
            </Link>
            {(user.department === "admin" ||
              /* user.department === "ceo" ||
              user.department === "gm" ||
              user.department === "sd" ||*/
              user.department === "sa" ||
              user.department === "sm") && (
              <button
                data-tip
                data-for="send"
                className={style.icon}
                // to={`/lead-list-edit/${row.original._id}`}
                onClick={() => {
                  setModalType("send");
                  setTargetUser({
                    fullName: row.original.name + " " + row.original.surname,
                    id: row.original._id,
                  });
                  setTargetProject(row.original.associatedProject);
                  setSendOwner({
                    ownerName: row.original.ownerNameSurname,
                    ownerId: row.original.owner,
                  });
                  setIsPopupOpen(true);
                }}
              >
                <ThumbIcon />
              </button>
            )}
            {(user.department === "admin" ||
              user.department === "ceo" ||
              user.department === "gm" ||
              user.department === "sd" ||
              user.department === "rd" ||
              user.department === "sm") && (
              <Link
                data-tip
                data-for="edit"
                className={style.icon}
                to={`/lead-list-edit/${row.original._id}`}
              >
                <EditIcon />
              </Link>
            )}
            {user.department === "admin" /*||
              user.department === "ceo" ||
              user.department === "gm" ||
              user.department === "sd" ||
              user.department === "rd" ||
              user.department === "sm"*/ && (
              <button
                data-tip
                data-for="delete"
                className={style.icon}
                // to={`/lead-list-edit/${row.original._id}`}
                onClick={() => {
                  setModalType("delete");
                  setTargetUser({
                    fullName: row.original.name + " " + row.original.surname,
                    id: row.original._id,
                  });
                  setIsPopupOpen(true);
                }}
              >
                <DeleteIcon />
              </button>
            )}
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (hasFilterData) {
      return;
    }
    setIsLoading(true);
    getAllLeads(
      user.token,
      user.userType,
      pagination.queryPageIndex + 1,
      pagination.queryPageSize,
      method,
      body
    )
      .then((res) => {
        res.data.sort((a, b) => {
          return new Date(b.creationDate) - new Date(a.creationDate);
        });
        setPagination((prev) => ({
          ...prev,
          totalCount: res.pageCount,
        }));
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user.token,
    user.userType,
    pagination.queryPageIndex,
    pagination.queryPageSize,
    hasFilterData,
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      pageCount: data.length > 0 ? Math.ceil(pagination.totalCount) : 0,
      manualPagination: hasFilterData ? false : true,
      initialState: {
        pageIndex: pagination.queryPageIndex,
        pageSize: pagination.queryPageSize,
        hiddenColumns: ["note", "userType", "channel", "owner"],
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      queryPageIndex: pageIndex,
    }));
  }, [pageIndex]);

  const popUpClose = () => {
    setModalType("");
    setTargetUser({
      fullName: "",
      id: "",
    });
    setTargetProject([]);
    setSendOwner({
      ownerName: "",
      ownerId: "",
    });
    setIsPopupOpen(false);
  };

  const successClick = () => {
    if (modalType === "") {
      return;
    } else if (modalType === "send") {
      sendLeadToContact(user.token, [targetUser.id], user.userType, sendOwner)
        .then((res) => {
          //console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      window.location.reload();
    } else if (modalType === "delete") {
      deleteALead(user.token, targetUser.id)
        .then((res) => {
          //console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      window.location.reload();
    }
    setModalType("");
    setTargetUser({
      fullName: "",
      id: "",
    });
    setTargetProject([]);
    setSendOwner({
      ownerName: "",
      ownerId: "",
    });
    setIsPopupOpen(false);
  };

  return (
    <>
      {isPopupOpen && (
        <LeadPopUp
          type={modalType}
          closeClick={popUpClose}
          successClick={successClick}
          fullName={targetUser.fullName}
          sendOwner={sendOwner}
          associatedProject={targetProject}
        />
      )}
      <div className={style.leadList}>
        <SubHeader
          leadMenu
          leadData={data}
          leadRows={rows}
          getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
          allColumns={allColumns}
        />
        <div className={style.content}>
          <SideFilter
            getPagination={pagination}
            setPagination={setPagination}
            setLeadData={setData}
            setHasFilterData={setHasFilterData}
            setNewMethod={setNewMethod}
            setNewBody={setNewBody}
          />

          <Table
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            page={page}
            prepareRow={prepareRow}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default LeadList;
