import style from "./userCreate.module.scss";
import { SubHeader } from "../../components";
import { GSMInput, TextInput, DropInput2 } from "../../components/Forms/Inputs";
import { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { useSelector } from "react-redux";
import { getAllProjects } from "../../lib/projectApi";
import { getAUser } from "../../lib/userApi";
import { createNewUser, editAUser } from "../../lib/userApi";
import UserPopup from "./UserPopup";
import ProjectSelection from "../../components/ui/projectSelection/ProjectSelection";

const UserCreate = () => {
  const user = useSelector((state) => state.user.userData);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [popUp, setPopUp] = useState(false);
  const [popUpErrorText, setPopUpErrorText] = useState("");
  const [allProjects, setAllProjects] = useState([]);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
  const [userCreate, setuserCreate] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
    department: "",
    userType: "Kurumsal", // client sadece kurumsala ekleme istiyor suanda
    assignedProject: [],
  });

  const isEdit = location.pathname.split("/")[2] === "kullanici-duzenle";

  useEffect(() => {
    if (isEdit) {
      getAUser(user.token, id)
        .then((res) => {
          //console.log(res.data.data);
          const projectIds = res.data.data.assignedProject.map((project) => {
            return project._id;
          });
          setuserCreate({
            name: res.data.data.name,
            surname: res.data.data.surname,
            email: res.data.data.email,
            phone: res.data.data.phone,
            department: res.data.data.department,
            userType: res.data.data.userType,
            assignedProject: projectIds,
          });
          res.data.data.assignedProject.forEach((element) => {
            //console.log(element);
            checkedCheckboxes.push(element._id);
          });
        })
        .catch((err) => {
          //console.log(err);
        });

      // setuserCreate({
      //   name: user.name,
      //   surname: user.surname,
      //   email: user.email,
      //   phone: user.phone,
      //   department: user.department,
      //   userType: user.userType,
      //   assignedProject: user.assignedProject,
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickHandler = () => {
    if (userCreate.name.length < 3) {
      setPopUpErrorText("Lütfen an az 3 harften oluşan isim giriniz");
      setPopUp(true);
      return;
    } else if (userCreate.surname.length < 3) {
      setPopUpErrorText("Lütfen an az 3 harften oluşan soyisim giriniz");
      setPopUp(true);
      return;
    } else if (userCreate.phone.length < 10) {
      setPopUpErrorText("Lütfen 10 haneden oluşan telefon numarası giriniz");
      setPopUp(true);
      return;
    } else if (userCreate.email.length < 3) {
      setPopUpErrorText("Lütfen an az 3 harften oluşan email giriniz");
      setPopUp(true);
      return;
      //TODO bir tane daha email ekle validatiion icin
    } else if (!userCreate.email.includes("@")) {
      setPopUpErrorText("Lütfen geçerli bir email giriniz");
      setPopUp(true);
      return;
    } else if (userCreate.userType === "") {
      setPopUpErrorText("Lütfen kullanıcı tipini seçiniz");
      setPopUp(true);
      return;
    } else if (userCreate.department === "") {
      setPopUpErrorText("Lütfen departman seçiniz");
      setPopUp(true);
      return;
    } else if (
      (userCreate.department === "sm" ||
        userCreate.department === "ss" ||
        userCreate.department === "sa" ||
        userCreate.department === "rd" ||
        userCreate.department === "sd") &&
      checkedCheckboxes.length === 0
    ) {
      setPopUpErrorText("Lütfen en az bir proje seçiniz");
      setPopUp(true);
      return;
    } /* else if (
      (userCreate.department === "sm" ||
        userCreate.department === "ss" ||
        userCreate.department === "sa") &&
      checkedCheckboxes.length > 1
    ) {
      setPopUpErrorText(
        "Satış Müdürü, Satış Uzmanı ve Satış Asistanı sadece 1 projeye atanabilir."
      );
      setPopUp(true);
      return;
    }
    */
    if (userCreate.department && checkedCheckboxes.length > 0) {
      userCreate.assignedProject = [];
      checkedCheckboxes.map((project) => {
        return userCreate.assignedProject.push(project);
      });
    } else {
      userCreate.assignedProject = [];
      setCheckedCheckboxes([]);
    }
    // if (
    //   userCreate.department === "ceo" ||
    //   userCreate.department === "gm" ||
    //   userCreate.department === "dms" ||
    //   userCreate.department === "mc"
    // ) {
    //   // yeni kullanci eklendiginde var olan butun projeler gidiyor
    //   allProjects?.map((project) => {
    //     return userCreate.assignedProject.push(project._id);
    //   });
    // }

    if (isEdit) {
      editAUser(user.token, id, userCreate)
        .then((res) => {
          navigate("/admin/kullaci-listesi", { replace: true });
        })
        .catch((err) => {
          //console.log(err);
        });
      return;
    }

    createNewUser(user.token, userCreate)
      .then((res) => {
        //console.log(res);
        navigate("/admin/kullaci-listesi", { replace: true });
        setuserCreate({
          name: "",
          surname: "",
          email: "",
          phone: "",
          department: "",
          userType: "",
        });
        userCreate.assignedProject = [];
      })
      .catch((err) => {
        setPopUpErrorText(
          "Bu e-posta veya telefon numarası zaten kayıtlı. Lütfen başka bir e-posta veya telefon numarası giriniz"
        );
        setPopUp(true);
      });
  };

  useEffect(() => {
    getAllProjects(user.token)
      .then((res) => {
        setAllProjects(res.data);
      })
      .catch((err) => {
        //console.log(err);
        navigate("/admin/kullaci-listesi", { replace: true });
      });
  }, [user.token, navigate]);

  const closePopHandler = () => {
    setPopUp(false);
  };

  return (
    <>
      {popUp && (
        <UserPopup closeClick={closePopHandler} errorText={popUpErrorText} />
      )}
      <SubHeader
        userCreate
        // setwClick={setwClick}
        form
        onClick={() => {
          clickHandler();
        }}
        path="/admin/kullaci-listesi"
        newButtonName={isEdit ? "Değiştir" : "Kaydet"}
        // buttonName={'Kaydet ve Yeni Ekle'}
      />
      <div className={style.createLeadContent}>
        <div className={style.leadForm}>
          <div className={style.leftLeadForm}>
            <TextInput
              value={userCreate.name}
              onChange={(text) => setuserCreate({ ...userCreate, name: text })}
              title={"İsim"}
            />
            <TextInput
              value={userCreate.surname}
              onChange={(text) =>
                setuserCreate({ ...userCreate, surname: text })
              }
              title={"Soyisim"}
            />

            <GSMInput
              value={userCreate.phone}
              onChange={(text) => setuserCreate({ ...userCreate, phone: text })}
              title={"Cep Telefonu"}
              placeholder={"Cep Telefonu"}
            />
            <TextInput
              value={userCreate.email}
              onChange={(text) => setuserCreate({ ...userCreate, email: text })}
              maxLength={"50"}
              title={"E-Posta"}
            />
          </div>
          <div className={style.rightLeadForm}>
            <DropInput2
              data={[
                { name: "Kurumsal", value: "Kurumsal" },
                // { name: "Bireysel", value: "Bireysel" },
                { name: "Yurt Dışı", value: "Yurt Dışı" },
              ]}
              setDropValue={(text) => {
                setuserCreate({ ...userCreate, userType: text });
              }}
              isDefault={userCreate.userType}
              placeholder={"Kullanıcı Tipi"}
              title={"Kullanıcı Tipi"}
            />
            <DropInput2
              data={[
                //admin: süper yönetici
                //ceo: YKB-yönetim kurulu başkanı
                //gm(General Manager): GM-genel müdür
                //mc(Marketing Coordinator): Pazarlama Koordinatörü
                //dms(Digital Marketing Specialist): DMS-dijital pazarlama uzmanı
                //sd(Sales director): Satış Direktörü
                //rd(Regional director): Bölge Müdürü
                //sm(Sales Manager): Satış Müdürü
                //ss(Sales Specialists): Satış Uzmanları
                //sa(Sales Assistant): Satış Asistanı
                //hr(Human Resources): İnsan Kaynakları
                //fd(Finance Department): Finans Departmanı
                //pd(Project Development): Proje Geliştirme
                { name: "Yönetim Kurulu Başkanı", value: "ceo" },
                { name: "Genel Müdür", value: "gm" },
                { name: "Pazarlama Koordinatörü", value: "mc" },
                { name: "Dijital Pazarlama Uzmanı", value: "dms" },
                { name: "Bölge Müdürü", value: "rd" },
                { name: "Satış Müdürü", value: "sm" },
                { name: "Satış Direktörü", value: "sd" },
                { name: "Satış Uzmanı", value: "ss" },
                { name: "Satış Asistanı", value: "sa" },
                { name: "İnsan Kaynakları", value: "hr" },
                { name: "Finans Departmanı", value: "fd" },
                { name: "Proje Geliştirme", value: "pd" },
                { name: "Dashboard", value: "dsb" },
              ]}
              setDropValue={(text) => {
                setuserCreate({ ...userCreate, department: text });
              }}
              isDefault={
                userCreate.department === "" ? "asda" : userCreate.department
              }
              placeholder={"Departman Seçiniz"}
              title={"Departman Seçiniz"}
            />
            {/* {(userCreate.department === "sm" ||
              userCreate.department === "ss" ||
              userCreate.department === "sa" ||
              userCreate.department === "rd" ||
              userCreate.department === "sd" ||
              userCreate.department === "pd"
              ) && ( */}
            {userCreate.department && (
              <ProjectSelection
                title="Proje Seçiniz"
                placeholder={
                  (checkedCheckboxes.length > 0
                    ? `(${checkedCheckboxes.length}) `
                    : "") + " Proje Seçiniz"
                }
                allProjects={allProjects}
                setCheckedCheckboxes={setCheckedCheckboxes}
                checkedCheckboxes={checkedCheckboxes}
              />
            )}

            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserCreate;
