import React, { useState, useEffect } from "react";
import { getAllProjects } from "../../../lib/projectApi";
import ProjectSelection from "../../../components/ui/projectSelection/ProjectSelection";
import { DateInput } from "../../../components/Forms/Inputs";
import { useSelector } from "react-redux";
import { BaseUrl } from "../../../ApiConfig";
import axios from "axios";
import fileDownload from "js-file-download";
import { Tooltip } from "react-tooltip";
import style from "./rapors.module.scss";
import { extractIdsFromObject } from "../../../utils/general";

function KaynakBazliIletisim() {
  const user = useSelector((state) => state.user.userData);
  const [allProjects, setAllProjects] = useState([]);
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [rapor, setRapor] = useState({
    selectedProjects: [],
    raporType: "kbi",
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    getAllProjects(user.token)
      .then((res) => {
        //console.log(res.data);
        setAllProjects(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user.token]);

  const buttonClickHandler = () => {
    if (rapor.date === "") {
      setError(true);
      setErrorText("Tarih alanı boş bırakılamaz.");
    }
    rapor.selectedProjects =
      checkedCheckboxes.length > 0
        ? checkedCheckboxes
        : extractIdsFromObject(allProjects);
    axios({
      url: `${BaseUrl}/api/rapor/get-rapor`,
      method: "POST",
      responseType: "blob",
      data: {
        rapor: rapor,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    }).then((response) => {
      fileDownload(
        response.data,
        `Kaynak Bazli Iletisim Raporu - ${new Date().toLocaleString()}.xlsx`
      );
    });
  };

  return (
    <div className={style.container}>
      {error && <div className={style.errorText}>{errorText}</div>}
      <Tooltip id="project" aria-haspopup="true">
        Proje seçimini boş bıraktığınızda <br />
        bütün projerler seçilecektir.
      </Tooltip>

      <ProjectSelection
        props={{
          "data-tip": "data-tip",
          "data-for": "project",
        }}
        title="Proje Seçiniz"
        placeholder={
          (checkedCheckboxes.length > 0
            ? `(${checkedCheckboxes.length}) `
            : "") + " Proje Seçiniz"
        }
        allProjects={allProjects}
        setCheckedCheckboxes={setCheckedCheckboxes}
        checkedCheckboxes={checkedCheckboxes}
      />

      <DateInput
        title={"Başlangıç Tarihi"}
        value={rapor.startDate}
        onChange={(text) =>
          setRapor({
            ...rapor,
            startDate: text,
          })
        }
      />
      <DateInput
        title={"Bitiş Tarihi"}
        value={rapor.endDate}
        onChange={(text) =>
          setRapor({
            ...rapor,
            endDate: text,
          })
        }
      />

      <button className={style.button} onClick={buttonClickHandler}>
        Kaynak Bazlı İletişim Raporu Oluştur
      </button>
    </div>
  );
}

export default KaynakBazliIletisim;
