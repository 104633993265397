import { useState } from "react";
import { Tooltip } from "react-tooltip";

import style from "./sidefilter.module.scss";
const DateBetweenInput = ({
  title,
  value,
  setChangeStart,
  setChangeEnd,
  data,
  setData,
  setManuelStartDate = null,
  setManuelEndDate = null,
}) => {
  const [isOpen, setOpen] = useState();
  const toggle = (e) => {
    setOpen(e.target.checked);
    if (data) {
      setData({
        ...data,
        createAtRealStart: "",
        createAtRealEnd: "",
      });
    } else {
      setManuelStartDate("");
      setManuelEndDate("");
    }
    // setChange("");
  };
  return (
    <>
      <label className={style.input}>
        <input onChange={(e) => toggle(e)} type="checkbox" />
        <h3>{title} </h3>
      </label>
      {isOpen ? (
        <>
          <div>
            <input
              data-for="startDate"
              data-tip="Başlangıç Tarihi!"
              style={{ marginBottom: "0.5rem" }}
              type="date"
              onChange={setChangeStart}
              hint="Başlangıç Tarihi"
            />
            <Tooltip
              id="startDate"
              type="warning"
              place="right"
              effect="solid"
            />
          </div>
          <div>
            <input
              data-for="endDate"
              data-tip="Bitiş Tarihi!"
              style={{ marginBottom: "0.5rem" }}
              type="date"
              onChange={setChangeEnd}
            />
            <Tooltip id="endDate" type="warning" place="right" effect="solid" />
          </div>
        </>
      ) : null}
    </>
  );
};

export default DateBetweenInput;
