import {
  Home,
  ApprovalList,
  ApprovalDetail,
  LeadList,
  LeadDetail,
  ContactDetail,
  CreateLead,
  CreateContact,
  CreateOffer,
  CreateNewSaleOffer,
  CreditModule,
  ContactList,
  EditLead,
  KvkList,
  ContactEdit,
  Test,
  BidDetail,
  BidEdit,
  CreateInventory,
  InventoryList,
  ProjectCreate,
  InventoryDetail,
  InventoryEdit,
  BidList,
  NoteList,
  NoteDetail,
  ProjectList,
  ProjectDetail,
  ProjectEdit,
  UserList,
  UserCreate,
  UserDetail,
  Rapor,
  Profile,
  AdminSystem,
} from "../containers";

const ROLES = {
  Admin: "admin",
  Ceo: "ceo",
  GeneralManager: "gm",
  RegionalDirector: "rd",
  MarketingCoordinator: "mc",
  DigitalMarketingSpecialist: "dms",
  SalesDirector: "sd",
  SalesManager: "sm",
  SalesSpecialists: "ss",
  SalesAssistant: "sa",
  HumanResources: "hr",
  FinanceDepartment: "fd",
  ProjectDevelopment: "pd",
  Dashboard: "dsb",
};

const routes = [
  {
    name: "home",
    path: "/",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      ROLES.MarketingCoordinator,
      ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      ROLES.SalesSpecialists,
      ROLES.SalesAssistant,
      ROLES.HumanResources,
      ROLES.FinanceDepartment,
      ROLES.ProjectDevelopment,
      ROLES.Dashboard,
    ],
    component: Home,
  },
  {
    name: "leadList",
    path: "/lead-list",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      ROLES.MarketingCoordinator,
      ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      ROLES.SalesSpecialists,
      ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      // ROLES.ProjectDevelopment,
    ],
    component: LeadList,
  },
  {
    name: "editLead",
    path: "/lead-list-edit/:id",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      // ROLES.MarketingCoordinator,
      //ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      // ROLES.SalesSpecialists,
      // ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      // ROLES.ProjectDevelopment,
    ],
    component: EditLead,
  },
  {
    name: "contactList",
    path: "/contact-list",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      ROLES.MarketingCoordinator,
      ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      ROLES.SalesSpecialists,
      ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      // ROLES.ProjectDevelopment,
    ],
    component: ContactList,
  },
  {
    name: "editContact",
    path: "/contact-list-edit/:id",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      // ROLES.MarketingCoordinator,
      // ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      // ROLES.SalesSpecialists,
      // ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      // ROLES.ProjectDevelopment,
    ],
    component: ContactEdit,
  },
  {
    name: "test",
    path: "/test",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      ROLES.MarketingCoordinator,
      ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      ROLES.SalesSpecialists,
      ROLES.SalesAssistant,
      ROLES.HumanResources,
      ROLES.FinanceDepartment,
      ROLES.ProjectDevelopment,
    ],
    component: Test,
  },
  {
    name: "leadDetail",
    path: "/lead-detail/:id",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      ROLES.MarketingCoordinator,
      ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      ROLES.SalesSpecialists,
      ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      // ROLES.ProjectDevelopment,
    ],
    component: LeadDetail,
  },
  {
    name: "contactDetail",
    path: "/contact-detail/:id",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      ROLES.MarketingCoordinator,
      ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      ROLES.SalesSpecialists,
      ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      // ROLES.ProjectDevelopment,
    ],
    component: ContactDetail,
  },
  {
    name: "createLead",
    path: "/create-lead",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      ROLES.MarketingCoordinator,
      ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      ROLES.SalesSpecialists,
      ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      // ROLES.ProjectDevelopment,
    ],
    component: CreateLead,
  },
  {
    name: "createContact",
    path: "/create-contact",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      //ROLES.MarketingCoordinator,
      //ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      ROLES.SalesSpecialists,
      ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      // ROLES.ProjectDevelopment,
    ],
    component: CreateContact,
  },
  {
    name: "detailBid",
    path: "/bid-detail/:id/:contactId",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      ROLES.MarketingCoordinator,
      ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      ROLES.SalesSpecialists,
      ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      // ROLES.ProjectDevelopment,
    ],
    component: BidDetail,
  },
  {
    name: "bidEdit",
    path: "/bid-list-edit/:id/:contactId",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      // ROLES.MarketingCoordinator,
      //ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      // ROLES.SalesSpecialists,
      // ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      // ROLES.ProjectDevelopment,
    ],
    component: BidEdit,
  },
  {
    name: "bidList",
    path: "/bid-list",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      ROLES.MarketingCoordinator,
      ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      ROLES.SalesSpecialists,
      ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      // ROLES.ProjectDevelopment,
    ],
    component: BidList,
  },
  {
    name: "approvalList",
    path: "/approval-list",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.SalesManager,
      //ROLES.RegionalDirector,
      // ROLES.MarketingCoordinator,
      //ROLES.DigitalMarketingSpecialist,
      //ROLES.SalesDirector,
      //ROLES.SalesManager,
      //ROLES.SalesSpecialists,
      //ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      // ROLES.ProjectDevelopment,
    ],
    component: ApprovalList,
  },

  {
    name: "detailApproval",
    path: "/approval-detail/:id/:contactId",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.SalesManager,
    ],
    component: ApprovalDetail,
  },

  {
    name: "createOffer",
    path: "/create-offer/:id",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      // ROLES.MarketingCoordinator,
      //ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      ROLES.SalesSpecialists,
      ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      // ROLES.ProjectDevelopment,
    ],
    component: CreateOffer,
  },
  {
    name: "createNewSaleOffer",
    path: "/create-new-sale-offer",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      // ROLES.MarketingCoordinator,
      //ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      ROLES.SalesSpecialists,
      ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      // ROLES.ProjectDevelopment,
    ],
    component: CreateNewSaleOffer,
  },
  {
    name: "createNewSaleOffer",
    path: "/credit-module",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      ROLES.MarketingCoordinator,
      ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      ROLES.SalesSpecialists,
      ROLES.SalesAssistant,
      ROLES.HumanResources,
      ROLES.FinanceDepartment,
      ROLES.ProjectDevelopment,
    ],
    component: CreditModule,
  },
  {
    name: "kvkList",
    path: "/kvk-list",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      //ROLES.GeneralManager,
      //ROLES.RegionalDirector,
      // ROLES.MarketingCoordinator,
      //ROLES.DigitalMarketingSpecialist,
      //ROLES.SalesDirector,
      //ROLES.SalesManager,
      //ROLES.SalesSpecialists,
      //ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      // ROLES.ProjectDevelopment,
    ],
    component: KvkList,
  },
  {
    name: "inventoryCreate",
    path: "/inventory-create",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      // ROLES.GeneralManager,
      // ROLES.RegionalDirector,
      // ROLES.MarketingCoordinator,
      //ROLES.DigitalMarketingSpecialist,
      // ROLES.SalesDirector,
      // ROLES.SalesManager,
      // ROLES.SalesSpecialists,
      // ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      // ROLES.ProjectDevelopment,
    ],
    component: CreateInventory,
  },
  {
    name: "inventoryList",
    path: "/inventory-list",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      ROLES.MarketingCoordinator,
      ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      ROLES.SalesSpecialists,
      ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      ROLES.ProjectDevelopment,
    ],
    component: InventoryList,
  },
  {
    name: "inventoryDetail",
    path: "/inventory-detail/:id",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      ROLES.MarketingCoordinator,
      ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      ROLES.SalesSpecialists,
      ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      ROLES.ProjectDevelopment,
    ],
    component: InventoryDetail,
  },
  {
    name: "inventoryEdit",
    path: "/inventory-list-edit/:id",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      // ROLES.RegionalDirector,
      // ROLES.MarketingCoordinator,
      // ROLES.DigitalMarketingSpecialist,
      // ROLES.SalesDirector,
      // ROLES.SalesManager,
      // ROLES.SalesSpecialists,
      // ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      // ROLES.ProjectDevelopment,
    ],
    component: InventoryEdit,
  },
  {
    name: "projectCreate",
    path: "/project-create",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      //ROLES.GeneralManager,
      //ROLES.RegionalDirector,
      // ROLES.MarketingCoordinator,
      //ROLES.DigitalMarketingSpecialist,
      //ROLES.SalesDirector,
      //ROLES.SalesManager,
      // ROLES.SalesSpecialists,
      // ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      //ROLES.ProjectDevelopment,
    ],
    component: ProjectCreate,
  },
  {
    name: "noteList",
    path: "/note-list",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      ROLES.MarketingCoordinator,
      ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      ROLES.SalesSpecialists,
      ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      // ROLES.ProjectDevelopment,
    ],
    component: NoteList,
  },
  {
    name: "noteDetail",
    path: "/note-detail/:id",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      ROLES.MarketingCoordinator,
      ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      ROLES.SalesSpecialists,
      ROLES.SalesAssistant,
      // ROLES.HumanResources,
      // ROLES.FinanceDepartment,
      // ROLES.ProjectDevelopment,
    ],
    component: NoteDetail,
  },
  {
    name: "projectList",
    path: "/project-list",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      ROLES.MarketingCoordinator,
      ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      ROLES.SalesSpecialists,
      ROLES.SalesAssistant,
      // ROLES.HumanResources,
      //ROLES.FinanceDepartment,
      ROLES.ProjectDevelopment,
    ],
    component: ProjectList,
  },
  {
    name: "ProjectDetail",
    path: "/project-detail/:id",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      ROLES.MarketingCoordinator,
      ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      ROLES.SalesSpecialists,
      ROLES.SalesAssistant,
      // ROLES.HumanResources,
      //ROLES.FinanceDepartment,
      ROLES.ProjectDevelopment,
    ],
    component: ProjectDetail,
  },
  {
    name: "ProjectEdit",
    path: "/project-list-edit/:id",
    exact: true,
    allowedRoles: [ROLES.Admin, ROLES.Ceo],
    component: ProjectEdit,
  },
  {
    name: "UserList",
    path: "/admin/kullaci-listesi",
    exact: true,
    allowedRoles: [ROLES.Admin],
    component: UserList,
  },
  {
    name: "UserCreate",
    path: "/admin/kullanici-olustur",
    exact: true,
    allowedRoles: [ROLES.Admin],
    component: UserCreate,
  },
  {
    name: "UserDetail",
    path: "/admin/kullanci/:id",
    exact: true,
    allowedRoles: [ROLES.Admin],
    component: UserDetail,
  },
  {
    name: "UserCreate",
    path: "/admin/kullanici-duzenle/:id",
    exact: true,
    allowedRoles: [ROLES.Admin],
    component: UserCreate,
  },
  {
    name: "AdminSystem",
    path: "/admin/sistem",
    exact: true,
    allowedRoles: [ROLES.Admin],
    component: AdminSystem,
  },
  {
    name: "Rapor",
    path: "/raporlar",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      ROLES.MarketingCoordinator,
      ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      //ROLES.SalesSpecialists,
      //ROLES.SalesAssistant,
      // ROLES.HumanResources,
      //ROLES.FinanceDepartment,
      ROLES.ProjectDevelopment,
    ],
    component: Rapor,
  },
  {
    name: "Profile",
    path: "/bilgilerim",
    exact: true,
    allowedRoles: [
      ROLES.Admin,
      ROLES.Ceo,
      ROLES.GeneralManager,
      ROLES.RegionalDirector,
      ROLES.MarketingCoordinator,
      ROLES.DigitalMarketingSpecialist,
      ROLES.SalesDirector,
      ROLES.SalesManager,
      ROLES.SalesSpecialists,
      ROLES.SalesAssistant,
      ROLES.HumanResources,
      ROLES.FinanceDepartment,
      ROLES.ProjectDevelopment,
    ],
    component: Profile,
  },
];

export default routes;
