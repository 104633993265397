import style from "./UserDetail.module.scss";
import { SubHeader } from "../../components";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAUser } from "../../lib/userApi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const convertDepertment = (department) => {
  //admin: süper yönetici
  //ceo: YKB-yönetim kurulu başkanı
  //gm(General Manager): GM-genel müdür
  //mc(Marketing Coordinator): Pazarlama Koordinatörü
  //sd(Sales director): Satış Direktörü
  //rd(Regional director): Bölge Müdürü
  //sm(Sales Manager): Satış Müdürü
  //ss(Sales Specialists): Satış Uzmanı
  //sa(Sales Assistant): Satış Asistanı
  //hr(Human Resources): İnsan Kaynakları
  //fd(Finance Department): Finans Departmanı
  //pd(Project Development): Proje Geliştirme

  switch (department) {
    case "admin":
      return "Süper Yönetici";
    case "ceo":
      return "YKB-Yönetim Kurulu Başkanı";
    case "gm":
      return "GM-Genel Müdür";
    case "mc":
      return "Pazarlama Koordinatörü";
    case "dms":
      return "Dijital Pazarlama Uzmanı";
    case "sd":
      return "Satış Direktörü";
    case "rd":
      return "Bölge Müdürü";
    case "sm":
      return "Satış Müdürü";
    case "ss":
      return "Satış Uzmanı";
    case "sa":
      return "Satış Asistanı";
    case "hr":
      return "İnsan Kaynakları";
    case "fd":
      return "Finans Departmanı";
    case "pd":
      return "Proje Geliştirme";
    case "dsb":
      return "Dashboard";
    default:
      return "";
  }
};

const LeadDetail = () => {
  const [data, setData] = useState();
  const user = useSelector((state) => state.user.userData);
  const { id } = useParams();

  useEffect(() => {
    getAUser(user.token, id)
      .then((res) => {
        //console.log(res.data.data);
        setData(res.data.data);
      })
      .catch((err) => {
        //console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const template =
    data?.phone?.length === 12
      ? /(\d{2})(\d{3})(\d{3})(\d{4})/
      : data?.phone?.length === 13
      ? /(\d{3})(\d{3})(\d{3})(\d{4})/
      : /(\d{3})(\d{3})(\d{4})/;
  const templateReplacer =
    data?.phone?.length === 12
      ? "($1) $2-$3"
      : data?.phone?.length === 13
      ? "+$1($2) $3-$4"
      : "($1) $2-$3";

  return (
    <div className={style.leadDetail}>
      {data ? (
        <div className={style.leadDetail}>
          <SubHeader userDetail userDetailData={data} />
          <div className={style.content}>
            <div className={style.info}>
              <div className={style.leadCard}>
                <div className={style.left}>
                  <div className={style.card}>
                    <h2>ID</h2>
                    <h1>{data._id ? data._id : ""}</h1>
                  </div>
                  <div className={style.card}>
                    <h2>İsim Soyisim</h2>
                    <h1>
                      {data.name ? data.name : ""}{" "}
                      {data.surname ? data.surname : ""}
                    </h1>
                  </div>
                  <div className={style.card}>
                    <h2>Cep Telefonu</h2>
                    <h1>
                      {data.phone
                        ? data?.phone
                            ?.toString()
                            .replace(template, templateReplacer)
                        : ""}
                    </h1>
                  </div>

                  <div className={style.card}>
                    <h2>E-Posta</h2>
                    <h1>{data.email ? data.email : ""}</h1>
                  </div>
                  <div className={style.card}>
                    <h2>Kullanıcı Tipi</h2>
                    <h1>{data.userType ? data.userType : ""}</h1>
                  </div>
                  <div className={style.card}>
                    <h2>Oluşturulma Tarihi</h2>
                    <h1>
                      {data.createdAt
                        ? new Date(data.createdAt).toLocaleString()
                        : ""}
                    </h1>
                  </div>
                </div>
                <div className={style.right}>
                  <div className={style.card}>
                    <h2>Pozisyon</h2>
                    <h1>
                      {data.department
                        ? convertDepertment(data.department)
                        : ""}
                    </h1>
                  </div>
                  <div className={style.card}>
                    <h2>Son Çevrim İçi Olduğu Tarih</h2>
                    <h1>
                      {data.lastOnline
                        ? new Date(data.lastOnline).toLocaleString()
                        : ""}
                    </h1>
                  </div>
                  <div className={style.card}>
                    <h2>Aktiflik Durumu</h2>
                    <h1>{data.isActive === true ? "Aktif" : "Susturulmuş"}</h1>
                  </div>
                  <br />
                  <hr />
                  <br />
                  <h2
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Tanımlanmış Projeler
                  </h2>
                  <br />
                  {data.assignedProject.map((item, index) => (
                    <div className={style.card}>
                      <h1>
                        <Link
                          data-tip
                          data-for="view"
                          className={style.icon}
                          to={`/project-detail/${item._id}`}
                        >
                          {index + 1} - {item.projectName}
                        </Link>
                      </h1>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default LeadDetail;
