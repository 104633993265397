import Logo from "../../assets/img/logo2.png";
import style from "./header.module.scss";
import HeaderButtons from "./HeaderButtons";
import { HomeIcon } from "../../assets/icons/index";
import { Tooltip } from "react-tooltip";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { department } = useSelector((state) => state.user.userData);
  const { t } = useTranslation();

  const closeMenu = () => {
    setMenuOpen(false);
  };
  let { pathname } = useLocation();
  const navigate = useNavigate();
  const splitPath = pathname ? pathname.split("/")[1] : null;

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Menüyü aç/kapat
  };

  // console.log(splitPath);
  const isAdminPanelHandler = () => {
    if (splitPath === "admin") {
      navigate("/", { replace: true });
    } else {
      navigate("/admin/kullaci-listesi", { replace: true });
    }
  };

  if (splitPath === "admin" && department === "admin") {
    return (
      <div className={style.header}>
        <div className={style.logo}>
          <img src={Logo} alt="logo" style={{ height: "80%" }} />
        </div>
        <div className={style.elements}>
          <ul>
            <li
              data-tip
              data-for="home"
              className={pathname === "/" ? style.active : null}
            >
              <Link to="/">
                <HomeIcon />
              </Link>
            </li>
            <li
              ///admin/kullanici-olustur
              className={
                splitPath
                  ? pathname === "/admin/kullaci-listesi" ||
                    pathname === "/admin/kullanici-olustur"
                    ? style.active
                    : null
                  : null
              }
            >
              <Link to="/admin/kullaci-listesi">Kullancılar</Link>
            </li>
            <li
              ///admin/kullanici-olustur
              className={
                splitPath
                  ? pathname === "/admin/sistem"
                    ? style.active
                    : null
                  : null
              }
            >
              <Link to="/admin/sistem">Sistem</Link>
            </li>
          </ul>
          <div className={style.buttonWrapper}>
            <HeaderButtons
              isAdminPanel={splitPath}
              isAdminPanelHandler={isAdminPanelHandler}
            />
          </div>
        </div>
      </div>
    );
  }

  const handleContactListClick = () => {
    closeMenu();
    if (window.location.pathname === "/contact-list") {
      window.location.reload();
    }
  };

  return (
    <header className={style.header}>
      <Link to="/">
        <div className={style.logo}>
          <img src={Logo} alt="logo" style={{ height: "80%" }} />
        </div>
      </Link>
      {department !== "dsb" && (
        <div className={style.elements}>
          <Tooltip id="home" type="warning" place="bottom" effect="solid">
            {t("home")}
          </Tooltip>
          <ul className={menuOpen ? style.menuOpen : ""}>
            <li
              data-tip
              data-for="home"
              className={pathname === "/" ? style.active : null}
            >
              <Link to="/" onClick={closeMenu}>
                <HomeIcon />
              </Link>
            </li>
            <li
              className={
                splitPath
                  ? pathname === "/lead-list" ||
                    splitPath === "lead-detail" ||
                    splitPath === "lead-list-edit" ||
                    splitPath === "create-lead"
                    ? style.active
                    : null
                  : null
              }
            >
              <Link to="/lead-list" onClick={closeMenu}>
                {t("ilişkiler")}
              </Link>
            </li>
            <li
              className={
                splitPath
                  ? pathname === "/contact-list" ||
                    splitPath === "contact-detail" ||
                    splitPath === "contact-list-edit" ||
                    splitPath === "create-contact"
                    ? style.active
                    : null
                  : null
              }
            >
              <Link to="/contact-list" onClick={handleContactListClick}>
                {t("kontaklar")}
              </Link>
            </li>
            {(department === "admin" || department === "ceo") && (
              <li className={pathname === "/kvk-list" ? style.active : null}>
                <Link to="/kvk-list" onClick={closeMenu}>
                  KVKK
                </Link>
              </li>
            )}
            <li
              className={
                splitPath === "create-offer" ||
                splitPath === "bid-detail" ||
                splitPath === "bid-list-edit" ||
                splitPath === "bid-list"
                  ? style.active
                  : null
              }
            >
              <Link to="/bid-list" onClick={closeMenu}>
                {t("aktiviteler")}
              </Link>
            </li>

            {["admin", "ceo", "gm", "sm"].includes(department) && (
              <li
                className={pathname === "/approval-list" ? style.active : null}
              >
                <Link to="/approval-list" onClick={closeMenu}>
                  {t("onaylar")}
                </Link>
              </li>
            )}
            <li
              className={
                splitPath === "note-list" || splitPath === "note-detail"
                  ? style.active
                  : null
              }
            >
              <Link to="/note-list" onClick={closeMenu}>
                {t("notlar")}
              </Link>
            </li>

            {/* <li className={pathname === '/credit-module' ? style.active : null}>
            <Link to="/">E-Postalar</Link>
          </li> */}
            {/* <li className={pathname === '/credit-module' ? style.active : null}>
            <Link to="/">Mesajlar</Link>
          </li> */}
            <li
              className={
                pathname === "/inventory-list" ||
                pathname === "/inventory-create" ||
                splitPath === "inventory-detail"
                  ? style.active
                  : null
              }
            >
              <Link to="/inventory-list" onClick={closeMenu}>
                {t("üniteler")}
              </Link>
            </li>
            <li
              className={
                pathname === "/project-list" ||
                splitPath === "project-detail" ||
                splitPath === "/project-list-edit" ||
                pathname === "/project-create"
                  ? style.active
                  : null
              }
            >
              <Link to="/project-list" onClick={closeMenu}>
                {t("projeler")}
              </Link>
            </li>
            {(department === "admin" ||
              department === "ceo" ||
              department === "gm" ||
              department === "rd" ||
              department === "sd" ||
              department === "sm" ||
              department === "dms" ||
              department === "mc" ||
              department === "pd") && (
              <li className={pathname === "/raporlar" ? style.active : null}>
                <Link to="/raporlar" onClick={closeMenu}>
                  {t("raporlar")}
                </Link>
              </li>
            )}
          </ul>
          <div
            className={`${style.buttonWrapper} ${
              menuOpen ? style.menuOpen : ""
            }`}
          >
            <HeaderButtons
              isAdminPanel={splitPath}
              isAdminPanelHandler={isAdminPanelHandler}
            />
          </div>
          <div className={style.menuToggle} onClick={toggleMenu}>
            ☰ Menü
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
