import React, { useState } from "react";
import style from "./excelExportPopup.module.scss";
import * as XLSX from "xlsx";
import { BaseUrl } from "../../ApiConfig";
import axios from "axios";
import Loader from "../ui/Loader/Loader";
import bosFile from "../../assets/sample/ORNEK_BOS.xls";
import ornekFile from "../../assets/sample/ORNEK.xls";

import { useTranslation } from "react-i18next";

const UNITE_KEYS = [
  "Proje ID",
  "Unite ID",
  "Mal Sahibi ID",
  "Proje Adı",
  "Ünite İsmi",
  "Ünite Sunum Şekli",
  "Ürün Statusu",
  "Ürün Tipi",
  "Manzara Kırılmaları",
  "Manzara",
  "Yön",
  "Daire Tipi",
  "Kullanıcı Tipi",
  "Teslim Şekli",
  "Bina Adı",
  "Bina Açılış Tarihi",
  "Websitesi",
  "Ulaşım",
  "Sosyal Olanklar",
  "Geliştirici",
  "İnşaat Alanı",
  "Verimlilik",
  "İklimlendirme",
  "Asansör Sayısı",
  "Zemin Üzeri Toplam Kat Sayısı",
  "Toplam Ofis Alanı",
  "Kat Seçenekleri",
  "Toplam Kat Sayısı",
  "Toplam Dükkan Alanı",
  "Dükkan Seçenekleri",
  "Mekanik Ayrıntı",
  "Dükkan Brüt Alanı",
  "Dükkan Açık Alanı Brüt Alanı",
  "Dükkan Hemzemin Depo Alanı",
  "Dükkan Alt Kat Depo Alanı",
  "Atölye Brüt Alanı (1-2-3. akslar)",
  "Atölye Brüt Alanı (4-5. akslar)",
  "Atölye Brüt Alanı (6-7. akslar)",
  "Atölye Brüt Alanı (8-9. akslar)",
  "Dükkan Saft Alanı",
  "Atölye Şaft Alanı",
  "Tavan Yüksekliği",
  "Oda Sayısı",
  "Balko Metrekare",
  "Ada",
  "Parsel",
  "Pafta",
  "Blok No",
  "Diş Kapı No",
  "Kat",
  "Bağımsız Bölüm No",
  "Açık Adres",
  "İl",
  "Ülke",
  "Cephe Genişliği (m)",
  "Brüt Tavan Yüksekliği",
  "Hemzemin Depo Alanı",
  "Alt Kat Depo Alanı Brüt Alanı",
  "Depo Şaft Alanı",
  "Açık Yükleme Alanı",
  "Tahsisli Çalışma Alanı",
  "İdari Ofis Alanı",
  "Sahanlık Alanı",
  "Ofis Brüt Alanı",
  "Tır Girebilme Durumu",
  "Kreyn Altyapısı",
  "Tahsisli Çatı Mekanik Alanı",
  "Toplam Satılabilir Alan",
  "Satış Brüt Metrekare",
  "Daire Brüt Metrekare",
  "Kat Brüt Metrekare",
  "Net Metrekare",
  "Bahçe Metrekare",
  "Havuz Metrekare",
  "Teras Metrekare",
  "Otopark Hakkı",
  "Vergi Durumu",
  "Döviz Birimi",
  "Metrekare Birim Satış Fiyatı",
  "Metrekare Birim Kira Bedeli",
  "Metrekare Birim Satış Bedeli",
  "KDV Oranı",
  "KDV Hariç Liste Fiyatı",
  "KDV Dahil Satış Fiyatı",
  "KDV Dahil Liste Fiyatı",
  "Kira Bedeli",
  "Aidat",
  "Toplam Metrekare Aidat",
  "Kirasız Dönem",
  "Mal Sahibi",
  "Not",
];

const UNIT_KEY_DATABASE = [
  "projectId",
  "inventoryId",
  "ownerId",
  "projectName",
  "inventoryName",
  "status",
  "inventoryCategory",
  "corporateStatus",
  "LandscapeRefractions",
  "Landscape",
  "Direction",
  "circleType",
  "userType",
  "deliveryShape",
  "buildingName",
  "buildingOpeningDate",
  "website",
  "transportation",
  "socialOpportunities",
  "developer",
  "constructionZone",
  "productivity",
  "airConditioning",
  "numberOfElevators",
  "floorAboveGroundTotalLength",
  "totalOfficeSpace",
  "floorOptions",
  "floorTotalLength",
  "totalStoreSpace",
  "storeOptions",
  "mechanicalDetail",
  "shopGrossArea",
  "shopOpenAreaGrossArea",
  "shopLevelStorageArea",
  "shopDownstairsStorageArea",
  "workshopGrossArea123",
  "workshopGrossArea45",
  "workshopGrossArea67",
  "workshopGrossArea89",
  "shopShaftArea",
  "workshopShaftArea",
  "ceilingHeight",
  "numberRooms",
  "balconyM2",
  "island",
  "parsel",
  "pafta",
  "blockNo",
  "exteriorDoorNo",
  "floor",
  "independentNo",
  "address",
  "city",
  "country",
  "facadeWidth",
  "grossCeilingHeight",
  "atLevelWarehouse",
  "grossAreaBasementWarehouse",
  "warehouseShaftArea",
  "openLoadingArea",
  "dedicatedWorkspace",
  "administrativeOfficeArea",
  "shelfArea",
  "officeGrossArea",
  "truckEntryStatus",
  "craneInfrastructure",
  "assignedRoofMechanicalSpace",
  "totalSalableArea",
  "brutM2",
  "flatBrutM2",
  "floorBrutM2",
  "netM2",
  "gardenM2",
  "poolM2",
  "terraceM2",
  "parkingRight",
  "taxStatus",
  "currency",
  "salesPriceM2",
  "salesRentM2",
  "salesPriceM2",
  "vatRate",
  "excludingVatListPrice",
  "withVatListPriceSatis",
  "withVatListPriceListe",
  "rentPrice",
  "dues",
  "duesTotalM2",
  "rentFreePeriod",
  "inventoryOwnerName",
  "note",
];

const UNITE_SUNUM_SEKLI_LIST = [
  "Kiraya uygun",
  "Kaporalı",
  "Opsiyonlu",
  "Kiraya uygun değil",
  "Satışa Açık (Stok)",
  "Satışa Açık (Opsiyonlu)",
  "Satışa Açık (Kaparolu)",
  "Satışa Kapalı",
  "Barter",
];
/*
const MANZARA_KIRILMALAR_LIST = [
  "Peyzaj",
  "Blok",
  "Deniz",
  "Göl",
  "Nehir",
  "Site Dışı",
  "Sokak/Cadde",
  "Orman",
];
*/
const YON_LIST = [
  "Kuzey",
  "Güney",
  "Doğu",
  "Batı",
  "Kuzey Batı",
  "Kuzey Doğu",
  "Güney Batı",
  "Güney Doğu",
];

const DOVIZ_BIRIMI_LIST = ["TRY", "USD", "EUR", "GBP", "RUB"];

const VAR_YOK_LIST = ["Var", "Yok"];
// const KDV_ORAN_LIST = [1, 8, 10, 18, 20];

const URUN_TIPI_LIST = ["dukkan", "ofis", "atolye", "konut", "villa"];
const URUN_STATUS_LIST = ["satılık", "kiralık", "satılıkveyakiralık"];

function TopluVeriYuklePopUp({ text, closeClick }) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [errorText, setErrorText] = useState("");
  const [fileName, setFileName] = useState(null);
  // const [file, setFile] = useState(null);

  const [excelData, setExcelData] = useState(null);

  if (errorText !== "") {
    setTimeout(() => {
      setErrorText("");
    }, 3000);
  }

  const dosyaGondermeHandler = () => {
    if (fileName === null) {
      return setErrorText("Dosya seçmediniz.");
    }
    if (excelData.lenght === 0) {
      return setErrorText("Dosya boş.");
    }
    if (excelData.length > 0) {
      let keys = Object.keys(excelData[0]);
      //if key values not same as  keys UNITE_KEYS then error
      for (let i = 0; i < keys.length; i++) {
        if (UNITE_KEYS.includes(keys[i]) === false) {
          return setErrorText(
            "Dosya başlıkları hatalı. Örnek dosyadaki gibi olmalıdır."
          );
        }
      }
    }

    for (let i = 0; i < excelData.length; i++) {
      const item = excelData[i];
      const projeID = item["Proje ID"];
      const uniteID = item["Unite ID"];
      const malSahibiID = item["Mal Sahibi ID"];
      const uniteIsmi = item["Ünite İsmi"];
      const urunStatusu = item["Ürün Statusu"]; // satılık, kiralık, satilikVeyaKiralik
      const urunTipi = item["Ürün Tipi"]; // dukkan, ofis, atolye, konut, villa
      const uniteSunumSekli = item["Ünite Sunum Şekli"]; // satis(kapora)

      // const manzaraKirilmalari = item["Manzara Kırılmaları"];
      // const manzara = item["Manzara"];
      const dovizBirimi = item["Döviz Birimi"];
      const yon = item["Yön"];
      const iklimlendirme = item["İklimlendirme"];
      const tirGirebilmeDurumu = item["Tır Girebilme Durumu"];
      const kdvOrani = item["KDV Oranı"];

      // -------------  uniteIsmi  ------------------------------------

      if (
        (uniteIsmi === "" || uniteIsmi === null || uniteIsmi === undefined) &&
        (uniteID === undefined || uniteID === null || uniteID === "")
      ) {
        return setErrorText("Ünite ismi boş olamaz.");
      }

      // ----------------  projeID  ---------------------------------

      if (
        (projeID === undefined || projeID === null || projeID === "") &&
        (uniteID === undefined || uniteID === null || uniteID === "")
      ) {
        return setErrorText("Proje ID ve UniteID boş olamaz.");
      }

      // ----------------  malSahibiID  ---------------------------------

      if (
        (malSahibiID === undefined ||
          malSahibiID === null ||
          malSahibiID === "") &&
        (uniteID === undefined || uniteID === null || uniteID === "")
      ) {
        return setErrorText("Mal Sahibi ID ve ve UniteID boş olamaz.");
      }

      // -----------------  urunTipi  --------------------------------

      if (
        (urunTipi === "" || urunTipi === null || urunTipi === undefined) &&
        (uniteID === undefined || uniteID === null || uniteID === "")
      ) {
        return setErrorText("Ürün Tipi ve ve UniteID boş olamaz.");
      }
      if (
        urunTipi !== "" &&
        !URUN_TIPI_LIST.includes(urunTipi) &&
        (uniteID === undefined || uniteID === null || uniteID === "")
      ) {
        return setErrorText(
          "Ürün Tipi hatalı.Bu alana sadece 'dukkan', 'ofis', 'atolye', 'konut', 'villa' girebilirsiniz.(türkçe karakterler kullanmayınız. örn: dukkan, ofis, atolye, konut, villa)"
        );
      }

      // ----------------  urunStatusu  ---------------------------------
      if (
        (urunStatusu === "" ||
          urunStatusu === null ||
          urunStatusu === undefined) &&
        (uniteID === undefined || uniteID === null || uniteID === "")
      ) {
        return setErrorText("Ürün Statusu ve UniteID boş olamaz.");
      }
      if (
        urunStatusu !== "" &&
        urunStatusu !== undefined &&
        !URUN_STATUS_LIST.includes(urunStatusu) &&
        (uniteID === undefined || uniteID === null || uniteID === "")
      ) {
        return setErrorText(
          "Ürün Statusu hatalı. Bu alana sadece 'satılık', 'kiralık', 'satılıkveyakiralık' girebilirsiniz.(türkçe karakterler kullanmayınız. örn: satılık, kiralık, satılıkveyakiralık)"
        );
      }

      // -----------------  uniteSunumSekli  --------------------------------

      if (
        uniteSunumSekli === "" &&
        (uniteID === undefined || uniteID === null || uniteID === "")
      ) {
        return setErrorText(
          "Ünite Sunum Şekli alanı ve UniteID boş bırakılamaz."
        );
      }
      if (
        uniteSunumSekli !== "" &&
        !UNITE_SUNUM_SEKLI_LIST.includes(uniteSunumSekli) &&
        (uniteID === undefined || uniteID === null || uniteID === "")
      ) {
        return setErrorText(
          "Ünite Sunum Şekli alanı hatalı. Bu alana 'Kiraya uygun', 'Kaporalı', 'Opsiyonlu', 'Kiraya uygun değil', 'Satışa Açık (Stok)', 'Satışa Açık (Opsiyonlu)', 'Satışa Açık (Kaparolu)', 'Satışa Kapalı' ve 'Barter' değerlerini giriniz."
        );
      }

      // ----------------  manzaraKirilmalari  --------------------------------
      /*
      if (
        manzaraKirilmalari !== "" && manzaraKirilmalari !== undefined && !MANZARA_KIRILMALAR_LIST.includes(manzaraKirilmalari)
      ) {
        return setErrorText(
          "Manzara Kırılmaları alanı hatalı. Bu alana 'Peyzaj', 'Blok', 'Deniz', 'Göl', 'Nehir', 'Site Dışı', 'Sokak/Cadde', 'Orman' değerlerini giriniz."
        );
      }
      */
      // ------------------  manzara  -------------------------------
      /*
      if (manzara !== "" && manzaraKirilmalari !== undefined && !MANZARA_KIRILMALAR_LIST.includes(manzara)) {
        return setErrorText(
          "Manzara alanı hatalı. Bu alana 'Peyzaj', 'Blok', 'Deniz', 'Göl', 'Nehir', 'Site Dışı', 'Sokak/Cadde', 'Orman' değerlerini giriniz."
        );
      }
      */
      // -------------------  yon  ------------------------------

      if (yon !== "" && yon !== undefined && !YON_LIST.includes(yon)) {
        return setErrorText(
          "Yön alanı hatalı. Bu alana 'Kuzey', 'Güney', 'Doğu', 'Batı', 'Kuzey Batı', 'Kuzey Doğu', 'Güney Batı', 'Güney Doğu' değerlerini giriniz."
        );
      }

      // -----------------  iklimlendirme --------------------------------

      if (
        iklimlendirme !== "" &&
        iklimlendirme !== undefined &&
        !VAR_YOK_LIST.includes(iklimlendirme)
      ) {
        return setErrorText(
          "İklimlendirme alanı hatalı. Bu alana 'Var' veya 'Yok' değerlerini giriniz."
        );
      }

      // ------------------  tirGirebilmeDurumu  -------------------------------

      if (
        tirGirebilmeDurumu !== "" &&
        tirGirebilmeDurumu !== undefined &&
        !VAR_YOK_LIST.includes(tirGirebilmeDurumu)
      ) {
        return setErrorText(
          "Tır Girebilme Durumu alanı hatalı. Bu alana 'Var' veya 'Yok' değerlerini giriniz."
        );
      }

      // -------------------  kdvOrani  ------------------------------

      // if (kdvOrani !== "" && kdvOrani !== undefined && !KDV_ORAN_LIST.includes(parseInt(kdvOrani))) {
      if (kdvOrani === "" && kdvOrani === undefined && kdvOrani === 0) {
        return setErrorText(
          // "KDV Oranı alanı hatalı. Bu alana 1, 8, 10, 18 veya 20 değerlerini giriniz."
          "KDV Oranı alanı hatalı. 0 dan büyük bir değer olmalı."
        );
      }

      // -------------------  doviz birimi ------------------------------
      if (
        dovizBirimi !== "" &&
        dovizBirimi !== undefined &&
        !DOVIZ_BIRIMI_LIST.includes(dovizBirimi)
      ) {
        return setErrorText(
          "Döviz Birimi alanı hatalı. Bu alana 'TRY', 'USD', 'EUR', 'GBP' değerlerini giriniz."
        );
      }
    }

    let newExcelDataWithUnitDatabaseKeys = [];

    excelData.forEach((item) => {
      const keys = Object.keys(item);
      const newItem = {};

      keys.forEach((key, keyIndex) => {
        const keyIndexInUnitKeys = UNITE_KEYS.findIndex(
          (unitKey) => unitKey === key
        );
        newItem[UNIT_KEY_DATABASE[keyIndexInUnitKeys]] = item[key];
      });
      newExcelDataWithUnitDatabaseKeys.push(newItem);
    });
    setIsLoading(true);
    axios
      .post(
        `${BaseUrl}/api/project/uploadExcel`,
        {
          data: newExcelDataWithUnitDatabaseKeys,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("AuthToken")}`,
          },
        }
      )
      .then((res) => {
        closeClick();
        setErrorText("");
        setExcelData([]);
        setIsLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          const errorMessage = err.response.data.message;
          setErrorText(errorMessage);
        }
      });
  };

  const dosyaSecmeHandler = (e) => {
    setErrorText("");
    setIsLoading(true);
    if (e.target.files.length === 0) {
      return setErrorText("Dosya seçmediniz.");
    }

    // if (e.target.files[0].name.split(".")[1] !== "xlsx") {
    //   e.target.value = "";
    //   return setErrorText("Dosya xlsx formatında olmalıdır.");
    // }
    setFileName(e.target.files[0].name);
    // setFile(e.target.files[0]);

    const [file] = e.target.files;
    const reader = new FileReader();

    // read excel in json format
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      setExcelData(json);
      setIsLoading(false);
    };
    reader.readAsBinaryString(file);
  };

  // const sampleFileDownloadHandler = () => {};

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className={style.cardPopupWrapper}>
        <div className={style.cardPopup}>
          <div className={style.popupHeader}>{text}</div>
          <hr />
          <div className={style.container}>
            <div
              style={{
                display: "flex !important",
                flexDirecton: "column !important",
              }}
            >
              {errorText && (
                <div
                  style={{
                    display: "flex !important",
                    flexDirecton: "column !important",
                    marginBottom: "20px",
                    background: "rgb(184, 100, 100)",
                    color: "white",
                    padding: "20px 10px",
                    borderRadius: "5px",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                >
                  {errorText}
                </div>
              )}

              <div
                style={{
                  display: "flex !important",
                  flexDirecton: "column !important",
                  justifyContent: "center !important",
                  alignItems: "center !important",
                  width: 500,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirecton: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                    // marginBottom: "35px",
                    // marginTop: "10px",
                  }}
                >
                  <a
                    href={bosFile}
                    download={`ORNEK_BOS.xls`}
                    style={{
                      padding: "10px 15px",
                      borderRadius: "5px",
                      background: "#1C3879",
                      color: "white",
                      textDecoration: "none",
                      display: "flex",
                      flexDirecton: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="ri-file-line"
                      style={{
                        fontSize: "20px",
                        marginBottom: "3px",
                        marginRight: "8px",
                      }}
                    />
                    Örnek Boş Dosya İndir
                  </a>
                  <a
                    href={ornekFile}
                    download={`ORNEK_DOLU.xls`}
                    style={{
                      padding: "10px 15px",
                      borderRadius: "5px",
                      background: "#607EAA",
                      color: "white",
                      textDecoration: "none",
                      display: "flex",
                      flexDirecton: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="ri-file-text-line"
                      style={{
                        fontSize: "20px",
                        marginBottom: "3px",
                        marginRight: "8px",
                      }}
                    />
                    Örnek Dolu Dosya İndir
                  </a>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirecton: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "40px",
                    marginBottom: "10px",
                  }}
                >
                  <div>
                    <input
                      type="file"
                      id="fileInput"
                      name="fileInput"
                      accept={[".xlsx", ".xls"]}
                      placeholder="Dosya Sec"
                      onChange={dosyaSecmeHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style.buttonContainer}>
            <button onClick={closeClick} className={style.closeModalPopup}>
              {t("vazgec")}
            </button>
            <button
              onClick={dosyaGondermeHandler}
              className={style.successModalPopup}
            >
              Verileri Yükle
            </button>
          </div>
        </div>
      </div>
      <div className={style.shadowBox}></div>
    </>
  );
}

export default TopluVeriYuklePopUp;
