import React, { useEffect, useState, useMemo } from "react";
import style from "./inventorylist.module.scss";
import { SubHeader, SideFilter } from "../../components";
import { useSelector } from "react-redux";
import { getAllInventory, deleteAInventory } from "../../lib/InventoryApi";
import IndeterminateCheckbox from "../../components/table/indeterminateCheckbox/IndeterminateCheckbox";
import { Tooltip } from "react-tooltip";
import Moment from "moment";
import { Link } from "react-router-dom";
import {
  ViewIcon,
  EditIcon /* , DeleteIcon */,
} from "../../assets/icons/index";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import Table from "../../components/table/customTable/Table";
import InventoryPopup from "./InventoryPopup";

import { useTranslation } from "react-i18next";

function InventoryList() {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [hasFilterData, setHasFilterData] = useState(false);
  const [pagination, setPagination] = useState({
    queryPageIndex: 0,
    queryPageSize: 25,
    totalCount: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.user.userData);
  const [targetInventory, setTargetInventory] = useState({
    id: "",
    projectId: "",
    projectName: "",
    InventoryName: "",
    numberOfBids: 0,
  });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [method, setNewMethod] = useState();
  const [body, setNewBody] = useState();

  useEffect(() => {
    if (hasFilterData) {
      return;
    }
    setIsLoading(true);
    getAllInventory(
      user.token,
      user.userType,
      pagination.queryPageIndex + 1,
      pagination.queryPageSize,
      method,
      body
    )
      .then((res) => {
        setPagination((prev) => ({
          ...prev,
          totalCount: res.pageCount,
        }));
        if (method === "excel") {
          setExcelData(res.data);
          setData([]);
        } else {
          setData(res.data);
          setExcelData([]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user.token,
    user.userType,
    pagination.queryPageIndex,
    pagination.queryPageSize,
    hasFilterData,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    method === "excel",
  ]);

  const statusConverter = (status) => {
    if (status === "Kiraya uygun") {
      return t("kirayaUygun");
    } else if (status === "Kaporalı") {
      return t("kaporali");
    } else if (status === "Opsiyonlu") {
      return t("opsiyonlu");
    } else if (status === "Kiraya uygun değil ") {
      return t("kirayaUygunDegil");
    } else if (status === "Satışa Açık (Stok)") {
      return t("satisaAcikStok");
    } else if (status === "Satışa Açık (Opsiyonlu)") {
      return t("satisaAcikOpsiyonlu");
    } /* else if (status === "Satışa Açık (Kaparolu)") {
      return t("satisaAcikKaporali");
    } */ else if (status === "Satış (Kaparo)") {
      return t("satisaKapaliKaparoli");
    } else if (status === "Satış (Sözleşme)") {
      return t("satisaKapaliSozlesmeli");
    } else if (status === "Satış (Görüşme Aşamasında)") {
      return t("satisaKapaliGorusmeAsamasi");
    } else if (status === "Satış (Opsiyon)") {
      return t("satisaKapaliOpsiyon");
    } else if (status === "Satış Onay") {
      return t("satisOnay");
    } else if (status === "Satışa Kapalı") {
      return t("satisaKapali");
    } else if (status === "Barter") {
      return t("barter");
    }
  };

  const urunStatusuConverter = (status) => {
    if (status === "satılık") {
      return t("satilik");
    } else if (status === "kiralık") {
      return t("kiralik");
    } else if (status === "satılıkveyakiralık") {
      return t("satilikVeyaKiralik");
    }
  };

  const columns = useMemo(
    () => [
      {
        id: "selection",
        disableFilters: true,
        groupByBoundary: true,
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <IndeterminateCheckbox
            isindeterminate={
              getToggleAllRowsSelectedProps().checked ? true : undefined
            }
            ischecked={
              getToggleAllRowsSelectedProps().checked ? true : undefined
            }
            {...getToggleAllRowsSelectedProps()}
          />
        ),
        Cell: ({ row }) => (
          <IndeterminateCheckbox
            ischecked={row.isSelected ? true : undefined}
            {...row.getToggleRowSelectedProps()}
          />
        ),
      },
      {
        Header: t("projeAdi"),
        accessor: "queryProjectName",
      },
      {
        Header: t("uniteIsmi"),
        accessor: "inventoryName",
      },
      {
        Header: t("urunStatusu"),
        id: "inventoryCategory",
        accessor: (row) =>
          row.inventoryCategory
            ? urunStatusuConverter(row.inventoryCategory)
            : "",
      },
      {
        Header: t("uniteSunumSekli"),
        id: "status",
        accessor: (row) => (row?.status ? statusConverter(row?.status) : ""),
      },
      {
        Header: t("il"),
        accessor: "city",
      },
      {
        Header: t("ulke"),
        accessor: "country",
      },
      {
        Header: t("urunTipi"),
        accessor: (d) => {
          if (d.corporateStatus) {
            return `${t(d.corporateStatus)}`;
          }
          return "";
        },
      },
      {
        Header: t("odaSayisi"),
        accessor: "numberRooms",
      },
      {
        Header: t("daireTipi"),
        accessor: "circleType",
      },
      {
        Header: t("manzara"),
        accessor: "Landscape",
      },
      {
        Header: t("yon"),
        accessor: "Direction",
      },
      {
        Header: t("netMetrekare"),
        accessor: (d) => {
          if (d.netM2) {
            return `${d.netM2.toFixed(2)} Net M2`;
          }
          return "0 Net M2";
        },
      },
      {
        Header: t("brutMetrekare"),
        accessor: (d) => {
          if (d.brutM2) {
            return `${d.brutM2.toFixed(2)} Brut M2`;
          }
          return "0 Brut M2";
        },
      },
      {
        Header: t("kdvDahilSatisFiyati"),
        id: "withVatListPrice",
        key: "withVatListPrice",
        accessor: (d) => {
          if (d.withVatListPrice) {
            const price = parseFloat(d.withVatListPrice).toLocaleString(
              "tr-TR",
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            );
            return `${price} ${d.currency}`;
          }
          return "0.00";
        },
        Cell: ({ value }) => (
          <div style={{ textAlign: "right", width: "-webkit-fill-available" }}>
            {value}
          </div>
        ),
      },
      {
        Header: t("olusturulmaTarihi"),
        id: "createDate",
        accessor: (d) => {
          return Moment(d.createDate).local().format("DD.MM.YYYY");
        },
        show: true,
      },
      {
        Header: t("islemler"),
        accessor: "actions",
        Cell: ({ row }) => (
          <div className={style.icons}>
            <Tooltip id="view" type="warning" place="bottom" effect="solid">
              {t("uniteDetaliGor")}
            </Tooltip>
            <Tooltip id="edit" type="warning" place="bottom" effect="solid">
              {t("uniteDuzenle")}
            </Tooltip>
            <Tooltip id="delete" type="warning" place="bottom" effect="solid">
              {t("uniteSil")}
            </Tooltip>
            <Link
              target="_blank"
              data-tip
              data-for="view"
              className={style.icon}
              to={`/inventory-detail/${row.original._id}`}
            >
              <ViewIcon />
            </Link>
            {["admin", "ceo", "gm"].includes(user.department) && (
              <Link
                target="_blank"
                data-tip
                data-for="edit"
                className={style.icon}
                to={`/inventory-list-edit/${row.original._id}`}
              >
                <EditIcon />
              </Link>
            )}
            {/* 
            {(user.department === "admin" || user.department === "ceo") && (              
              <button
                data-tip
                data-for="delete"
                className={style.icon}
                // to={`/lead-list-edit/${row.original._id}`}
                onClick={() => {
                  setTargetInventory({
                    id: row.original._id,
                    projectId: row.original.ownerProject._id,
                    projectName: row.original.queryProjectName,
                    InventoryName: row.original.inventoryName,
                    numberOfBids: row.original.bids.length,
                  });
                  setIsPopupOpen(true);
                }}
              >
                <DeleteIcon />
              </button>
            )}
            */}
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      pageCount: data.length > 0 ? Math.ceil(pagination.totalCount) : 0,
      manualPagination: hasFilterData ? false : true,
      initialState: {
        pageIndex: pagination.queryPageIndex,
        pageSize: pagination.queryPageSize,
        hiddenColumns: ["city"],
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      queryPageIndex: pageIndex,
      queryPageSize: pageSize,
    }));
  }, [pageIndex, pageSize]);

  const popUpClose = () => {
    setTargetInventory({
      id: "",
      projectId: "",
      projectName: "",
      InventoryName: "",
      numberOfBids: 0,
    });
    setIsPopupOpen(false);
  };

  const successClick = () => {
    deleteAInventory(user.token, [targetInventory.id])
      .then((res) => {
        //console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    window.location.reload();
    setIsPopupOpen(false);
    setTargetInventory({
      id: "",
      projectId: "",
      projectName: "",
      InventoryName: "",
      numberOfBids: 0,
    });
  };

  return (
    <>
      {isPopupOpen && (
        <InventoryPopup
          closeClick={popUpClose}
          successClick={successClick}
          targetInventory={targetInventory}
        />
      )}
      <div className="column">
        <div className={style.leadList}>
          <SubHeader
            method={method}
            setNewMethod={setNewMethod}
            inventoryMenu
            inventoryRows={rows}
            // selectedInventory={selectedInventory}
            inventoryListData={excelData}
            getToggleHideAllColumnsPropsFromInventory={
              getToggleHideAllColumnsProps
            }
            allInventoryColumns={allColumns}
          />
          <div className={style.content}>
            <SideFilter
              setInventoryData={setData}
              setHasFilterData={setHasFilterData}
              getPagination={pagination}
              setPagination={setPagination}
              setNewMethod={setNewMethod}
              setNewBody={setNewBody}
            />
            <Table
              getTableProps={getTableProps}
              headerGroups={headerGroups}
              getTableBodyProps={getTableBodyProps}
              page={page}
              prepareRow={prepareRow}
              gotoPage={gotoPage}
              canPreviousPage={canPreviousPage}
              previousPage={previousPage}
              nextPage={nextPage}
              canNextPage={canNextPage}
              pageCount={pageCount}
              setPageSize={setPageSize}
              pageIndex={pageIndex}
              pageOptions={pageOptions}
              pageSize={pageSize}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default InventoryList;
