import { SubHeader, SideFilter } from "../../components";
import style from "./userList.module.scss";
import { useEffect, useState, useMemo } from "react";
import IndeterminateCheckbox from "../../components/table/indeterminateCheckbox/IndeterminateCheckbox";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import { useSelector } from "react-redux";
import {
  getAllUsers,
  deleteAUser,
  changeuserActiveStatus,
} from "../../lib/userApi";
import Moment from "moment";
import { Link } from "react-router-dom";
import Table from "../../components/table/customTable/Table";
import { Tooltip } from "react-tooltip";
// import LeadPopUp from "./LeadPopUp";
import UserListPopUp from "./UserListPopUp";

import {
  // DeleteIcon,
  EditIcon,
  // ThumbIcon,
  ViewIcon,
  BanIcon,
  UnBanIcon,
  RefreshIcon,
} from "../../assets/icons/index";
// import { replace } from "formik";

const UserList = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFilterData, setHasFilterData] = useState(false);
  const [pagination, setPagination] = useState({
    queryPageIndex: 0,
    queryPageSize: 25,
    totalCount: null,
  });
  const user = useSelector((state) => state.user.userData);
  const [targetUser, setTargetUser] = useState({
    fullName: "",
    id: "",
  });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [modalType, setModalType] = useState("");

  const columns = useMemo(
    () => [
      {
        id: "selection",
        disableFilters: true,
        groupByBoundary: true,
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <IndeterminateCheckbox
            isindeterminate={
              getToggleAllRowsSelectedProps().checked ? true : undefined
            }
            ischecked={
              getToggleAllRowsSelectedProps().checked ? true : undefined
            }
            {...getToggleAllRowsSelectedProps()}
          />
        ),
        Cell: ({ row }) => (
          <IndeterminateCheckbox
            ischecked={row.isSelected ? true : undefined}
            {...row.getToggleRowSelectedProps()}
          />
        ),
      },
      {
        Header: "ID",
        accessor: (d) => {
          return d._id.length > 7 ? d._id.slice(0, 7) + "..." : d._id;
        },
      },
      {
        Header: "Isim Soyisim",
        accessor: (row) => `${row.name} ${row.surname}`,
      },
      {
        Header: "E-mail",
        accessor: "email",
      },
      {
        Header: "Telefon",
        accessor: "phone",
      },
      {
        Header: "Pozisyon",
        id: "department",
        accessor: (d) => {
          if (d.department === "ceo") {
            return "YKB";
          } else if (d.department === "gm") {
            return "Genel Müdür";
          } else if (d.department === "mc") {
            return "Pazarlama Koordinatörü";
          } else if (d.department === "dms") {
            return "Dijital Pazarlama Uzmanı";
          } else if (d.department === "sd") {
            return "Satış Direktörü";
          } else if (d.department === "rd") {
            return "Bölge Müdürü";
          } else if (d.department === "sm") {
            return "Satış Müdürü";
          } else if (d.department === "ss") {
            return "Satış Uzmanı";
          } else if (d.department === "sa") {
            return "Satış Asistanı";
          } else if (d.department === "hr") {
            return "İnsan Kaynakları";
          } else if (d.department === "fd") {
            return "Finans Departmanı";
          } else if (d.department === "pd") {
            return "Proje Geliştirme";
          } else if (d.department === "dsb") {
            return "Dashboard";
          }
        },
      },
      {
        Header: "Kullanci Tipi",
        accessor: "userType",
      },
      {
        Header: "Oluşturulma Tarihi",
        accessor: (row) => Moment(row.createdAt).local().format("DD.MM.YYYY"),
      },
      {
        Header: "İşlemler",
        accessor: "actions",
        Cell: ({ row }) => (
          <div className={style.icons}>
            <Tooltip id="view" type="warning" place="bottom" effect="solid">
              Kullancıyı Detaylı Görüntüle
            </Tooltip>
            <Tooltip id="reset" type="warning" place="bottom" effect="solid">
              Kullancının Şifresini Sıfırla
            </Tooltip>
            <Tooltip id="edit" type="warning" place="bottom" effect="solid">
              Kullancıyı Düzenle
            </Tooltip>
            <Tooltip id="delete" type="warning" place="bottom" effect="solid">
              Kullancıyı Sil
            </Tooltip>
            <Tooltip id="ban" type="warning" place="bottom" effect="solid">
              Kullancıyı Sustur
            </Tooltip>
            <Tooltip id="unban" type="warning" place="bottom" effect="solid">
              Kullancıyı Aktifleştir
            </Tooltip>
            <Link
              data-tip
              data-for="view"
              className={style.icon}
              to={`/admin/kullanci/${row.original._id}`}
            >
              <ViewIcon />
            </Link>
            <button
              data-tip
              data-for="reset"
              className={style.icon}
              // to={`/lead-list-edit/${row.original._id}`}
              onClick={() => {
                setModalType("reset");
                setTargetUser({
                  fullName: row.original.name + " " + row.original.surname,
                  id: row.original._id,
                });
                setIsPopupOpen(true);
              }}
            >
              <RefreshIcon />
            </button>
            <Link
              data-tip
              data-for="edit"
              className={style.icon}
              to={`/admin/kullanici-duzenle/${row.original._id}`}
            >
              <EditIcon />
            </Link>
            {/*
            <button
              data-tip
              data-for="delete"
              className={style.icon}
              // to={`/lead-list-edit/${row.original._id}`}
              onClick={() => {
                setModalType("delete");
                setTargetUser({
                  fullName: row.original.name + " " + row.original.surname,
                  id: row.original._id,
                });
                setIsPopupOpen(true);
              }}
            >
              <DeleteIcon />
            </button>
            */}
            {row.original.isActive === true ? (
              <button
                data-tip
                data-for="ban"
                className={style.icon}
                // to={`/lead-list-edit/${row.original._id}`}
                onClick={() => {
                  setModalType("ban");
                  setTargetUser({
                    fullName: row.original.name + " " + row.original.surname,
                    id: row.original._id,
                  });
                  setIsPopupOpen(true);
                }}
              >
                <BanIcon />
              </button>
            ) : (
              <button
                data-tip
                data-for="unban"
                className={style.icon}
                // to={`/lead-list-edit/${row.original._id}`}
                onClick={() => {
                  setModalType("unban");
                  setTargetUser({
                    fullName: row.original.name + " " + row.original.surname,
                    id: row.original._id,
                  });
                  setIsPopupOpen(true);
                }}
              >
                <UnBanIcon />
              </button>
            )}
          </div>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    if (hasFilterData) {
      return;
    }
    setIsLoading(true);
    getAllUsers(
      user.token,
      user.userType,
      pagination.queryPageIndex + 1,
      pagination.queryPageSize
    )
      .then((res) => {
        setData(res.data);
        setPagination((prev) => ({
          ...prev,
          totalCount: res.pageCount,
        }));
        setIsLoading(false);
        //console.log(res.data.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [
    user.token,
    user.userType,
    pagination.queryPageIndex,
    pagination.queryPageSize,
    hasFilterData,
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // selectedFlatRows,
    // allColumns,
    // getToggleHideAllColumnsProps,
    state: { pageIndex, pageSize /*, selectedRowIds */ },
  } = useTable(
    {
      columns,
      data,
      pageCount: data.length > 0 ? Math.ceil(pagination.totalCount) : 0,
      manualPagination: hasFilterData ? false : true,
      initialState: {
        pageIndex: pagination.queryPageIndex,
        pageSize: pagination.queryPageSize,
        hiddenColumns: ["ID", "note", "userType", "channel", "owner", "phone"],
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      queryPageIndex: pageIndex,
      queryPageSize: pageSize,
    }));
  }, [pageIndex, pageSize]);

  const popUpClose = () => {
    setModalType("");
    setTargetUser({
      fullName: "",
      id: "",
    });
    setIsPopupOpen(false);
  };

  const successClick = () => {
    if (modalType === "delete") {
      //console.log(targetUser.id);
      deleteAUser(user.token, targetUser.id)
        .then((res) => {
          //console.log(res);
        })
        .catch((err) => {
          //console.log(err);
          // setModalType("error");
          // TODO ERROR MODAL
        });
    } else if (modalType === "ban") {
      changeuserActiveStatus(user.token, targetUser.id)
        .then((res) => {
          //console.log(res);
        })
        .catch((err) => console.log(err));
    } else if (modalType === "unban") {
      changeuserActiveStatus(user.token, targetUser.id)
        .then((res) => {
          //console.log(res);
        })
        .catch((err) => console.log(err));
    }

    window.location.reload();
    setModalType("");
    setTargetUser({
      fullName: "",
      id: "",
    });
    setIsPopupOpen(false);
  };

  return (
    <>
      {isPopupOpen && (
        <UserListPopUp
          type={modalType}
          closeClick={popUpClose}
          successClick={successClick}
          fullName={targetUser.fullName}
        />
      )}
      <div className={style.leadList}>
        <SubHeader
          userMenu
          // leadMenu
          // leadData={data}
          // leadRows={rows}
          // getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
          // allColumns={allColumns}
        />
        <div className={style.content}>
          <SideFilter
            setUserData={setData}
            setHasFilterData={setHasFilterData}
            getPagination={pagination}
            setPagination={setPagination}
          />

          <Table
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            page={page}
            prepareRow={prepareRow}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default UserList;
