import style from "./notelist.module.scss";
import { SideFilter, SubHeader } from "../../components";
import { useEffect, useState, useMemo } from "react";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import { useSelector } from "react-redux";
import IndeterminateCheckbox from "../../components/table/indeterminateCheckbox/IndeterminateCheckbox";
import { getAllNotes } from "../../lib/noteApi";
import Moment from "moment";
import { Tooltip } from "react-tooltip";
import { Link } from "react-router-dom";
import {
  ViewIcon,
  /* DeleteIcon, */ NotUserIcon,
} from "../../assets/icons/index";
import Table from "../../components/table/customTable/Table";
import Popup from "../../containers/CreateLead/LeadPopup";
import { BaseUrl } from "../../ApiConfig";
import axios from "axios";

import { useTranslation } from "react-i18next";

const NoteList = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [hasFilterData, setHasFilterData] = useState(false);
  const [pagination, setPagination] = useState({
    queryPageIndex: 0,
    queryPageSize: 25,
    totalCount: null,
  });
  const user = useSelector((state) => state.user.userData);
  const [popup, setPopup] = useState(false);
  const [popupData, setPopupData] = useState({
    noteTitle: "",
    title: "",
    id: "",
    contactId: "",
    type: "",
    errorText: "",
  });

  const [method, setNewMethod] = useState();
  const [body, setNewBody] = useState();

  useEffect(() => {
    if (hasFilterData) {
      return;
    }
    setIsLoading(true);
    getAllNotes(
      user.token,
      user.userType,
      pagination.queryPageIndex + 1,
      pagination.queryPageSize,
      method,
      body
    )
      .then((res) => {
        setPagination((prev) => ({
          ...prev,
          totalCount: res.pageCount,
        }));
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user.token,
    user.userType,
    pagination.queryPageIndex,
    pagination.queryPageSize,
    hasFilterData,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "selection",
        disableFilters: true,
        groupByBoundary: true,
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <IndeterminateCheckbox
            isindeterminate={
              getToggleAllRowsSelectedProps().checked ? true : undefined
            }
            ischecked={
              getToggleAllRowsSelectedProps().checked ? true : undefined
            }
            {...getToggleAllRowsSelectedProps()}
          />
        ),
        Cell: ({ row }) => (
          <IndeterminateCheckbox
            ischecked={row.isSelected ? true : undefined}
            {...row.getToggleRowSelectedProps()}
          />
        ),
      },
      {
        Header: t("olusturanPersonel"),
        accessor: "noteOwner",
      },
      {
        Header: t("iliskiliProje"),
        accessor: "queryProjectName",
      },
      {
        Header: t("baslik"),
        accessor: "title",
      },
      {
        Header: t("kullanciTipi"),
        accessor: "userType",
      },
      {
        Header: t("kontak"),
        accessor: "queryContactName",
      },
      {
        Header: t("iliskiliAktivite"),
        accessor: "queryBidName",
      },
      {
        Header: t("notTarih"),
        accessor: "meetingDate",
      },
      {
        Header: t("olusturulmaTarihi"),
        id: "createdAt",
        accessor: (d) => {
          return Moment(d.createdAt).local().format("DD.MM.YYYY");
        },
        show: true,
      },
      {
        Header: t("islemler"),
        accessor: "actions",
        Cell: ({ row }) => (
          <div className={style.icons}>
            <Tooltip id="view" type="warning" place="bottom" effect="solid">
              {t("notDetayliGoruntule")}
            </Tooltip>
            <Tooltip id="delete" type="warning" place="bottom" effect="solid">
              {t("notSil")}
            </Tooltip>
            <Tooltip id="user" type="warning" place="bottom" effect="solid">
              {t("notKontagiGor")}
            </Tooltip>

            <Link
              target="_blank"
              data-tip
              data-for="view"
              className={style.icon}
              to={`/note-detail/${row.original._id}`}
            >
              <ViewIcon />
            </Link>
            <Link
              target="_blank"
              data-tip
              data-for="user"
              className={style.icon}
              to={`/contact-detail/${row.original.contactId._id}`}
            >
              <NotUserIcon />
            </Link>
            {/*
            <button
              data-tip
              data-for="delete"
              className={style.icon}
              onClick={() => {
                setPopup(true);
                setPopupData({
                  noteTitle: row.original.title,
                  title: t("notPopUpTitle"),
                  id: row.original._id,
                  contactId: row.original.contactId._id,
                  type: "delete",
                  errorText: row.original.title + t("notPopUpText"),
                });
              }}
            >
              <DeleteIcon />
            </button>
            */}
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      pageCount: data.length > 0 ? Math.ceil(pagination.totalCount) : 0,
      manualPagination: hasFilterData ? false : true,
      initialState: {
        pageIndex: pagination.queryPageIndex,
        pageSize: pagination.queryPageSize,
        hiddenColumns: ["noteOwner", "queryBidName", "userType"],
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      queryPageIndex: pageIndex,
    }));
  }, [pageIndex]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      queryPageSize: pageSize,
    }));
  }, [pageSize]);

  const popUpSucessHandler = () => {
    if (popupData.id === "" || popupData.contactId === "") {
      setPopup(false);
      return;
    }
    axios
      .delete(
        `${BaseUrl}/api/note/delete/${popupData.id}/${popupData.contactId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error.response);
      });
    setPopup(false);
    setPopupData({
      noteTitle: "",
      title: "",
      id: "",
      contactId: "",
      type: "",
      errorText: "",
    });
  };

  return (
    <>
      {popup && (
        <Popup
          title={popupData.title}
          type={popupData.type}
          errorText={popupData.errorText}
          successClick={popUpSucessHandler}
          closeClick={() => {
            setPopup(false);
          }}
        />
      )}
      <div className={style.noteList}>
        <SubHeader
          getToggleHideAllColumnsPropsFromNotes={getToggleHideAllColumnsProps}
          allNotesColumns={allColumns}
          noteList
          noteData={data}
          AllNoteData={data}
          form
        />
        <div className={style.content}>
          <SideFilter
            setNoteData={setData}
            setHasFilterData={setHasFilterData}
            getPagination={pagination}
            setPagination={setPagination}
            setNewMethod={setNewMethod}
            setNewBody={setNewBody}
          />
          <Table
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            page={page}
            prepareRow={prepareRow}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default NoteList;
