import {
  NewButton,
  StandartButton,
  ProcessButton4,
  ProcessButton6,
} from "./Buttons";
import { useState, useRef } from "react";
import style from "./subheader.module.scss";
// import { useNavigate } from "react-router";
import ExcelExportPopup from "./ExcelExportPopup.jsx";
import IndeterminateCheckbox from "../table/indeterminateCheckbox/IndeterminateCheckbox";
import { DownIcon, UpIcon } from "../../assets/icons/index";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

const CheckBox = ({ getToggleHideAllColumnsProps, allColumns }) => {
  const [select, setSelect] = useState(false);
  const { t } = useTranslation();
  const checkboxMenu = useRef(null);
  const closeOpenMenus = (e) => {
    if (
      checkboxMenu.current &&
      select &&
      !checkboxMenu.current.contains(e.target)
    ) {
      setSelect(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  return (
    <div className={style.checkboxContact} ref={checkboxMenu}>
      <button className={style.button} onClick={() => setSelect(!select)}>
        {t("kolonSeciniz")}
        {select ? <UpIcon /> : <DownIcon />}
      </button>
      {select ? (
        <div className={style.select}>
          <div>
            <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />{" "}
            {t("hepsiniSec")}
          </div>
          {allColumns.map((column) => (
            <div key={column.id}>
              <label>
                <input type="checkbox" {...column.getToggleHiddenProps()} />{" "}
                {column.Header}
              </label>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

const FormMenu = ({
  deletes,
  onClick,
  isBid,
  path,
  buttonName,
  newButtonName,
  setwClick,
  createLead,
  userCreate,
  editLead,
  editContact,
  createContact,
  noteList,
  noteData,
  createBid,
  setBid,
  createBidHistoryId,
  editBid,
  createInventory,
  createProject,
  projectEdit,
  setSelectedMenu,
  editInventory,
  setSelectedCorporateStatus,
  getToggleHideAllColumnsPropsFromNotes,
  allNotesColumns,
  rapor,
  adminSystem,
}) => {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const [excelData, setExcelData] = useState([]);
  const [excelPopup, setExcelPopup] = useState(false);
  const [headers, setHeaders] = useState([]);

  let userType = user.userType;
  const _onClick1 = () => {
    onClick();
    if (createBid) {
      setTimeout(() => {
        // window.location.reload();
      }, 2000);
    } else if (setwClick) {
      setwClick("1");
    } else if (createInventory) {
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    } else if (createProject) {
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    }
  };
  const _onClick2 = () => {
    onClick();
    if (createBid) {
      // setTimeout(() => {
      //   navigate(`/contact-detail/${createBidHistoryId}`);
      //   navigate();
      // }, 3000);
    } else if (setwClick) {
      setwClick("2");
    } else if (createInventory) {
      // setTimeout(() => {
      //   navigate(`/inventory-list`);
      //   navigate();
      // }, 1500);
    } else if (createProject) {
      // setTimeout(() => {
      //   navigate(`/inventory-list`, { replace: true });
      // }, 3000);
    }
  };

  const excelExportAndDownload = (data) => {
    setExcelPopup(true);
    setHeaders([
      t("kontak"),
      t("email"),
      t("gsm"),
      t("phone"),
      t("olusturanPersonel"),
      t("kullanciTipi"),
      t("olusturulmaTarihi"),
      t("iliskiliAktivite"),
      t("iliskiliProje"),
      t("urunTipi"),
      t("baslik"),
      t("not"),
    ]);

    const newData = data.map((item) => {
      return {
        [t("kontak")]: item.queryContactName,
        [t("email")]: item.queryEmail,
        [t("gsm")]: item.queryGsm,
        [t("phone")]: item.queryPhone,
        [t("olusturanPersonel")]: item.noteOwner,
        [t("kullanciTipi")]: item.userType,
        [t("olusturulmaTarihi")]: item.createAtReal,
        [t("iliskiliAktivite")]: item.bidName,
        [t("iliskiliProje")]: item.queryProjectName,
        [t("urunTipi")]: item.queryInventoryName,
        [t("baslik")]: item.title,
        [t("not")]: item.note,
      };
    });
    setExcelData(newData);
  };

  const exelDownloaded = () => {
    setExcelPopup(false);
  };

  return (
    <div className={style.formMenu}>
      {excelPopup ? (
        <ExcelExportPopup
          closeClick={exelDownloaded}
          headers={headers}
          paddingVal="40px 10px"
          data={excelData}
          fileName={t("notlar")}
          text="Filtrelediğiniz Notlara ait Excel başlıklarını seçiniz:"
        />
      ) : null}
      {rapor ? (
        <div
          style={{
            marginLeft: "26px",
            width: "100%",
            marginTop: "17px",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          {t("raporlar")}
        </div>
      ) : null}
      {createLead ? (
        <div
          style={{
            marginLeft: "26px",
            width: "100%",
            marginTop: "17px",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          {t("yeniIliskiKayitFormu")}
        </div>
      ) : null}
      {userCreate ? (
        <div
          style={{
            marginLeft: "26px",
            width: "100%",
            marginTop: "17px",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          {t("yeniKullaniciKayitFormu")}
        </div>
      ) : null}
      {createInventory ? (
        <div
          style={{
            marginLeft: "26px",
            width: "100%",
            marginTop: "17px",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          {t("yeniUrunTipiKayitFormu")}
        </div>
      ) : null}
      {createBid ? (
        <div
          style={{
            marginLeft: "26px",
            width: "100%",
            marginTop: "17px",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          {t("yeniAktiviteKayitFormu")}
        </div>
      ) : null}
      {editLead ? (
        <div
          style={{
            marginLeft: "26px",
            width: "100%",
            marginTop: "17px",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          {t("iliskiGuncellemeFormu")}
        </div>
      ) : null}
      {editContact ? (
        <div
          style={{
            marginLeft: "26px",
            width: "100%",
            marginTop: "17px",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          {t("kontakGuncellemeFormu")}
        </div>
      ) : null}
      {createContact ? (
        <div
          style={{
            marginLeft: "26px",
            width: "100%",
            marginTop: "17px",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          {t("yeniKontakKayitFormu")}
        </div>
      ) : editBid ? (
        <div
          style={{
            marginLeft: "26px",
            width: "100%",
            marginTop: "17px",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          {t("aktiviteGuncellemeFormu")}
        </div>
      ) : createProject ? (
        <div
          style={{
            marginLeft: "26px",
            width: "100%",
            marginTop: "17px",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          {t("yeniProjeKayitFormu")}
        </div>
      ) : projectEdit ? (
        <div
          style={{
            marginLeft: "26px",
            width: "100%",
            marginTop: "17px",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          {t("projeGuncellemeFormu")}
        </div>
      ) : editInventory ? (
        <div
          style={{
            marginLeft: "26px",
            width: "100%",
            marginTop: "17px",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          {t("urunTipiGuncellemeFormu")}
        </div>
      ) : noteList ? (
        <div
          style={{
            marginLeft: "26px",
            width: "100%",
            marginTop: "17px",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          {t("notlar")}
        </div>
      ) : null}
      {adminSystem ? (
        <div
          style={{
            marginLeft: "26px",
            width: "100%",
            marginTop: "17px",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          {t("sistem")}
        </div>
      ) : null}

      <div className={style.left}>
        {isBid ? <h1>{t("kontagaAktiviteOlustur")}</h1> : null}
      </div>
      <div className={style.right}>
        {path ? <StandartButton title={t("iptal")} path={path} /> : null}

        {deletes ? <StandartButton title={t("sil")} /> : null}
        {/* {createBid ? (
          <ProcessButton3 setSelectValue={setSelectBidType} setBid={setBid} />
        ) : null} */}
        {createInventory ? (
          <>
            {userType === "Kurumsal" ? (
              <ProcessButton6 setSelectValue={setSelectedCorporateStatus} />
            ) : null}
            <ProcessButton4 setSelectValue={setSelectedMenu} />
          </>
        ) : null}
        {buttonName ? (
          <NewButton onClick={() => _onClick1()} title={buttonName} />
        ) : null}

        {newButtonName ? (
          <NewButton title={newButtonName} onClick={() => _onClick2()} />
        ) : null}
        {noteList && (
          <>
            <CheckBox
              getToggleHideAllColumnsProps={
                getToggleHideAllColumnsPropsFromNotes
              }
              allColumns={allNotesColumns}
            />
            {(user.department === "admin" || user.department === "ceo") && (
              <NewButton
                title={t("veriyiDisaAktar")}
                icon={"exel"}
                onClick={() => {
                  excelExportAndDownload(noteData);
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FormMenu;
