import React from "react";
import style from "./elements.module.scss";
import { EditIcon } from "../../assets/icons";
import PDF from "../../containers/BidDetail/PDF";
import { NumericFormat } from "react-number-format";
import { ContractPDF } from "../../containers";
import { StyleSheet } from "@react-pdf/renderer";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function ContactBidItem({ bidsData, setBidsData, contactData }) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  let contactId = window.location.pathname.split("/")[2];
  const styles = StyleSheet.create({
    page: {
      display: "flex",
      fontFamily: "Roboto",
      flexDirection: "column",
      backgroundColor: "white",
      padding: 48,
      fontSize: 12,
      fontWeight: 400,
    },
    title: {
      fontWeight: 500,
    },
    caption: {
      fontWeight: 500,
    },
  });

  const bidStageConverter = (stage) => {
    if (stage === "Satış(Kaparo)") {
      return t("satisKaparo");
    } else if (stage === "Satış(Sözleşme)") {
      return t("satisSozlesme");
    } else if (stage === "Takipte/Olumlu") {
      return t("takipteOlumlu");
    } else if (stage === "Teklif aşamasında") {
      return t("teklifAsamasinda");
    } else if (stage === "Tekrar görüşülecek") {
      return t("tekrarGorusulucek");
    } else if (stage === "Opsiyon") {
      return t("opsiyon");
    } else if (stage === "Bilgi Verildi") {
      return t("bilgiVerildi");
    } else if (stage === "Sıcak Müşteri") {
      return t("sicakMusteri");
    } else if (stage === "İptal") {
      return t("iptal");
    } else if (stage === "Evrak Temini") {
      return t("evrakTemini");
    } else if (stage === "Başarısız Kapanış") {
      return t("basarisizKapanis");
    } else if (stage === "Sunum") {
      return t("sunum");
    }
  };
  const currencyConvert = (value) => {
    if (value === "TRY") {
      return "₺";
    } else if (value === "USD") {
      return "$";
    } else if (value === "EUR") {
      return "€";
    } else if (value === "GBP") {
      return "£";
    } else if (value === "RUB") {
      return "₽";
    } else {
      return "₺";
    }
  };

  return bidsData
    ? bidsData.map((item) => {
        let date = new Date(item.createAtReal);
        let fullDate =
          date.getDate() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getFullYear();
        return (
          <div
            key={`bidsData-${item.id}`}
            style={{ zIndex: "0" }}
            className={style.contactBidİtem}
          >
            <Link
              style={{ display: "flex", width: "95%" }}
              to={`/bid-detail/${item.id}/${
                window.location.pathname.split("/")[2]
              }`}
            >
              <input
                type="checkbox"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  let value = e.target.checked;
                  setBidsData(
                    bidsData.map((sd) => {
                      if (sd.id === item.id) {
                        sd.select = value;
                      }
                      return sd;
                    })
                  );
                }}
                checked={item.select}
                style={{ marginLeft: 10 }}
              />
              <div>
                <span
                  className={style.label}
                  style={
                    item?.inventoryId?.isSuccessfullClosing === true &&
                    item?.inventoryId?.successfullClosingContactId === contactId
                      ? { backgroundColor: "#32E332" }
                      : item?.inventoryId?.isSuccessfullClosing === true &&
                        item?.inventoryId?.successfullClosingContactId !==
                          contactId
                      ? { backgroundColor: "#5499C7" }
                      : //kırılma noktası
                      item?.bidStage === "Sunum"
                      ? { backgroundColor: "#F1C40F" }
                      : item.bidStage === "Aktivite"
                      ? { backgroundColor: "#F1C40F" }
                      : item.bidStage === "Müzakere"
                      ? { backgroundColor: "#F1C40F" }
                      : item.bidStage === "Opsiyon"
                      ? { backgroundColor: "#F1C40F" }
                      : item.bidStage === "Kaparo"
                      ? { backgroundColor: "#F1C40F" }
                      : item.bidStage === "Sözleşme"
                      ? { backgroundColor: "#32E332" }
                      : item.bidStage === "Başarısız Kapanış"
                      ? { backgroundColor: "#C0392B" }
                      : null
                  }
                ></span>
              </div>
              <div>{item?.bidName}</div>
              <div>
                {bidsData ? item?.inventoryId?.inventoryOwnerId?.name : null}
                {bidsData ? item?.inventoryId?.inventoryOwnerId?.surname : null}
              </div>
              <div>{fullDate}</div>
              <div>{item?.bidShape}</div>
              <div>{item?.projeName}</div>
              <div>{item?.inventoryName}</div>
              <div>
                <NumericFormat
                  style={{
                    color: "#333e48",
                    width: "100%",
                    textAlign: "center",
                  }}
                  value={item.bidAmount}
                  isNumericString
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  suffix={currencyConvert(item?.currency)}
                />
              </div>
              <div>
                %{" "}
                {item.discountRate ? Number(item.discountRate).toFixed(0) : ""}
              </div>
              <div>{bidStageConverter(item.bidStage)}</div>
            </Link>
            {(user.department !== "hr" ||
              user.department !== "fd" ||
              user.department !== "pd") && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <ContractPDF
                  style={styles}
                  data={item}
                  contactData={contactData}
                />
                <PDF data={item} contactData={contactData} />
                <Link
                  style={{ marginRight: "10px" }}
                  to={`/bid-list-edit/${item.id}/${contactData._id}`}
                >
                  <EditIcon />
                </Link>
              </div>
            )}
          </div>
        );
      })
    : null;
}

export default ContactBidItem;
