import { FilterIcon, BackIcon, RightIcon } from "../../assets/icons";
import style from "./subheader.module.scss";
import * as XLSX from "xlsx";
import axios from "axios";
import { BaseUrl } from "../../ApiConfig";
import { useNavigate } from "react-router-dom";
import React, { useRef } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const handleExcelFile = (e) => {
  //console.log(e.target.files[0]);
  const excelFile = e.target.files[0];
  const reader = new FileReader();

  reader.onload = (evt) => {
    var data = evt.target.result;
    var workbook = XLSX.read(data, {
      type: "binary",
    });
    var productsArray = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[workbook.SheetNames[0]]
    );

    //console.log(productsArray);

    axios
      .post(`${BaseUrl}/api/project/uploadExcel`, {
        inventoryList: productsArray,
      })
      .then((res) => {
        //console.log("response ... ... ", res);
      })
      .catch((err) => console.log("err ... ... ", err));
  };

  reader.readAsBinaryString(excelFile);
};

const StandartButton = ({ title, path, click }) => {
  return (
    <Link onClick={click} to={path} className={style.standart}>
      {title}
    </Link>
  );
};
const StandartButton1 = ({ title, click, inventoryChangeStatus }) => {
  return (
    <button
      onClick={click}
      style={inventoryChangeStatus ? { minWidth: "110px" } : null}
      className={style.standart}
    >
      {title}
    </button>
  );
};

const FilterButton = () => {
  return (
    <button className={style.filterButton}>
      <FilterIcon />
    </button>
  );
};

const AllButton = ({ title }) => {
  return null;
};

const NewButton = ({ title, onClick, icon }) => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  return title === t("topluVeriYukle") ? (
    <>
      <button
        style={{
          width: "150px",
          whiteSpace: "nowrap",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "5px",
        }}
        onClick={() => {
          inputRef.current.click();
        }}
        type="submit"
        className={style.newButton}
      >
        {
          <i
            style={{ fontSize: "18px" }}
            className="ri-upload-cloud-2-line"
          ></i>
        }
        {title}
      </button>

      <input
        ref={inputRef}
        type="file"
        onChange={(e) => {
          handleExcelFile(e);

          setTimeout(() => {
            navigate(0);
          }, 1500);
        }}
        style={{ visibility: "hidden" }}
      />
    </>
  ) : (
    <button
      style={{
        width: "180px",
        whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "5px",
      }}
      onClick={onClick}
      type="submit"
      className={style.newButton}
    >
      {icon ? (
        <i style={{ fontSize: "18px" }} className="ri-file-download-line"></i>
      ) : title === t("kaydet") ? (
        <i style={{ fontSize: "18px" }} className="ri-save-line"></i>
      ) : title === t("degistir") ? (
        <i style={{ fontSize: "18px" }} className="ri-send-plane-fill"></i>
      ) : title === t("iceVeriYukle") ? (
        <i style={{ fontSize: "18px" }} className="ri-file-upload-line"></i>
      ) : (
        <i style={{ fontSize: "18px" }} className="ri-add-line"></i>
      )}
      {title}
    </button>
  );
};

const DocumentInputIMG = ({
  title2,
  title,
  setDocumentFile,
  documentFile,
  placeholder,
  id,
}) => {
  return (
    <div
      className={style.documentDrop}
      style={{ marginLeft: "-1.8rem", marginBottom: "1.2rem" }}
    >
      <h2>{title}</h2>
      <label htmlFor={id}>
        <h2 style={{ width: "100%", textAlign: "start", paddingLeft: "10px" }}>
          {documentFile
            ? documentFile.name
            : placeholder
            ? placeholder.substr(0, 22)
            : title2}{" "}
        </h2>
        {/* <DocumentIcon /> */}
      </label>
      <input
        accept="image/png, image/jpeg"
        type="file"
        id={id}
        onChange={(e) => setDocumentFile(e.target.files[0])}
      />
    </div>
  );
};

const ExcelButton = ({
  title,
  onClick,
  title2,
  setDocumentFile,
  documentFile,
  placeholder,
  id,
}) => {
  return (
    <button
      style={{}}
      onClick={onClick}
      //type="submit"
      //className={style.newButton}
      //title2="Bağımsız Bölüm Planı Yükleyiniz"
      {...title}
    >
      <label htmlFor={id}>
        <h2 style={{ textAlign: "center" }}>
          {documentFile
            ? documentFile.name
            : placeholder
            ? placeholder.substr(0, 22)
            : title2}
          {" Toplu Veri Yükleyin"}
        </h2>
      </label>
      <input
        name="asdfasdf"
        className={style.newButton}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" // .csv, 97-2003 xls, 2007+ xls dosyaları kabul et
        type="file"
        id={id}
        onChange={(e) => setDocumentFile(e.target.files[0])}
      />
    </button>
  );
};

const ProcessButton = ({ selectValue, setSelectValue, title }) => {
  const { t } = useTranslation();
  const toggle = (e) => {
    setSelectValue(e);
    if (!selectValue === "kvkk") {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };
  return (
    <select
      defaultValue="default"
      className={style.processButton}
      onChange={(e) => toggle(e.target.value)}
      value={selectValue}
    >
      <option value="default" disabled>
        {title}
      </option>
      {/* <option value="kalifiye">Kontaklara Gönder</option> */}
      {/*  TODO: BU SECENEK kontak gonderirken personel secme dolayisiyla kapatilmistir */}
      <option value="add">{t("iliskiyiKontaklaraGonder")}</option>
      <option value="delete">{t("secilenleriSil")}</option>
      {/* <option value="kvkk">KVKK</option> */}
    </select>
  );
};
const ProcessButton2 = ({ selectValue, title, setSelectValue }) => {
  const { t } = useTranslation();
  const toggle = (e) => {
    setSelectValue(e);
    // if (!selectValue === "kvkk") {
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 3000);
    // }
  };
  return (
    <select
      defaultValue="default"
      className={style.processButton}
      onChange={(e) => toggle(e.target.value)}
      value={selectValue}
    >
      <option value="default" disabled>
        {t("cokluIslemler")}
      </option>
      {/* <option value="delete">{t("secilenleriSil")}</option> */}
      {/* <option value="kvkk">KVKK</option> */}
    </select>
  );
};
const ProcessButton3 = ({ setSelectValue, setBid }) => {
  const { t } = useTranslation();
  const toggle = (e) => {
    setSelectValue(e);
    setBid((prev) => {
      return { ...prev, bidShape: e };
    });
  };
  return (
    <select
      className={style.processButton}
      onChange={(e) => toggle(e.target.value)}
      defaultValue=""
    >
      <option value="" disabled>
        {t("aktiviteTipiSeciniz")}
      </option>
      <option value="Satış Departmanı">{t("satisDepertmani")}</option>
      <option value="Kiralama Departmanı">{t("kiralamaDepartmani")}</option>
    </select>
  );
};
const ProcessButton4 = ({ setSelectValue }) => {
  const { t } = useTranslation();
  const toggle = (e) => {
    setSelectValue(e);
  };
  return (
    <select
      className={style.processButton}
      onChange={(e) => toggle(e.target.value)}
      defaultValue=""
    >
      <option value="" disabled>
        {t("birUrunStatusSeciniz")}
      </option>
      <option value="satılık">{t("satilik")}</option>
      <option value="kiralık">{t("kiralik")}</option>
      <option value="satılıkveyakiralık">{t("satilikVeyaKiralik")}</option>
    </select>
  );
};
const ProcessButton6 = ({ setSelectValue }) => {
  const { t } = useTranslation();
  const toggle = (e) => {
    setSelectValue(e);
  };
  return (
    <select
      className={style.processButton}
      onChange={(e) => toggle(e.target.value)}
      defaultValue=""
    >
      <option value="" disabled>
        {t("birUrunTipiSeciniz")}
      </option>
      <option value="dukkan">{t("dukkan")}</option>
      <option value="ofis">{t("ofis")}</option>
      <option value="atolye">{t("atolye")}</option>
      <option value="konut">{t("konut")}</option>
      <option value="villa">{t("villa")}</option>
    </select>
  );
};
const ProcessButton5 = ({ setSelectValue, selectValue }) => {
  const { t } = useTranslation();
  const toggle = (e) => {
    setSelectValue(e);
  };
  return (
    <select
      defaultValue="default"
      className={style.processButton}
      onChange={(e) => toggle(e.target.value)}
      value={selectValue}
    >
      <option value="default" disabled>
        {t("cokluIslemler")}
      </option>
      {/* <option value="statuschange">Statü Değiştir</option> */}
      {/* <option value="delete">{t("secilenleriSil")}</option> */}
    </select>
  );
};
const LeftButton = ({ href }) => {
  return (
    <Link className={style.leftButton} to={href}>
      <BackIcon />
    </Link>
  );
};

const RightButton = () => {
  return (
    <button className={style.rightButton}>
      <RightIcon />
    </button>
  );
};

export {
  ExcelButton,
  DocumentInputIMG,
  FilterButton,
  AllButton,
  NewButton,
  ProcessButton,
  LeftButton,
  RightButton,
  StandartButton,
  ProcessButton2,
  ProcessButton3,
  ProcessButton4,
  StandartButton1,
  ProcessButton5,
  ProcessButton6,
};
