import React from "react";
import style from "./ApprovalList.module.scss";
import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { SubHeader, SideFilter } from "../../components";
import Popup from "../../components/Forms/Popup";
import {
  getAllApprovals,
  sendApprove,
  sendApproveCancel,
} from "../../lib/approvalApi";
import { Link } from "react-router-dom";
import Moment from "moment";
import { Tooltip } from "react-tooltip";
import IndeterminateCheckbox from "../../components/table/indeterminateCheckbox/IndeterminateCheckbox";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import Table from "../../components/table/customTable/Table";
import { ViewIcon, ThumbIcon, BanIcon } from "../../assets/icons/index";
import ApprovalPopUp from "./ApprovalPopUp";

import { useTranslation } from "react-i18next";

function ApprovalList() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.user.userData);
  const [data, setData] = useState([]);
  const [hasFilterData, setHasFilterData] = useState(false);
  const [pagination, setPagination] = useState({
    queryPageIndex: 0,
    queryPageSize: 25,
    totalCount: null,
  });

  const [modalType, setModalType] = useState("");
  const [targetInventory, setTargetInventory] = useState({
    fullName: "",
    inventoryId: "",
    id: "",
  });
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [method, setNewMethod] = useState();
  const [body, setNewBody] = useState();

  const [popup, setPopup] = useState("");

  const popUpClose = () => {
    setModalType("");
    setTargetInventory({
      fullName: "",
      inventoryId: "",
      id: "",
    });
    setIsPopupOpen(false);
  };

  useEffect(() => {
    if (hasFilterData) {
      return;
    }
    setIsLoading(true);
    getAllApprovals(
      user.token,
      user.userType,
      pagination.queryPageIndex + 1,
      pagination.queryPageSize,
      method,
      body
    )
      .then((res) => {
        res.data.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        setPagination((prev) => ({
          ...prev,
          totalCount: res.pageCount,
        }));
        setData(res.data.reverse());
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user.token,
    user.userType,
    pagination.queryPageIndex,
    pagination.queryPageSize,
    hasFilterData,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "selection",
        disableFilters: true,
        groupByBoundary: true,
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <IndeterminateCheckbox
            isindeterminate={
              getToggleAllRowsSelectedProps().checked ? true : undefined
            }
            ischecked={
              getToggleAllRowsSelectedProps().checked ? true : undefined
            }
            {...getToggleAllRowsSelectedProps()}
          />
        ),
        Cell: ({ row }) => (
          <IndeterminateCheckbox
            ischecked={row.isSelected ? true : undefined}
            {...row.getToggleRowSelectedProps()}
          />
        ),
      },
      {
        Header: t("projeAdi"),
        accessor: "projeName",
      },
      {
        Header: t("uniteIsmi"),
        accessor: "inventoryName",
      },
      {
        Header: t("durum"),
        accessor: "bidStage",
      },
      {
        Header: t("urunStatusu"),
        accessor: "inventoryId.inventoryCategory",
      },
      {
        Header: t("urunTipi"),
        accessor: "inventoryId.corporateStatus",
      },
      {
        Header: t("musteriAdi"),
        accessor: "queryContactName",
      },
      {
        Header: t("olusturanPersonel"),
        accessor: "ownerBid",
      },
      {
        Header: t("olusturmaTarihi"),
        id: "createdAt",
        accessor: (row) =>
          Moment(row.creationDate).local().format("DD.MM.YYYY"),
        show: true,
      },
      {
        Header: t("islemler"),
        accessor: "actions",
        Cell: ({ row }) => (
          <div className={style.icons}>
            <Tooltip id="view" type="warning" place="bottom" effect="solid">
              {t("onayDetaylari")}
            </Tooltip>
            <Tooltip id="approve" type="warning" place="bottom" effect="solid">
              {t("onayla")}
            </Tooltip>
            <Tooltip id="cancel" type="warning" place="bottom" effect="solid">
              {t("iptal")}
            </Tooltip>
            {(user.department === "admin" ||
              user.department === "ceo" ||
              user.department === "sm") && (
              <Link
                data-tip
                data-for="view"
                className={style.icon}
                to={`/approval-detail/${row.original._id}/${
                  row.original.contactId ? row.original.contactId._id : ""
                }`}
              >
                <ViewIcon />
              </Link>
            )}
            {(user.department === "admin" ||
              user.department === "ceo" ||
              user.department === "sm") && (
              <button
                data-tip
                data-for="approve"
                className={style.icon}
                onClick={() => {
                  setModalType("approve");
                  setTargetInventory({
                    fullName: row.original.queryContactName,
                    inventoryId: row.original.inventoryId._id,
                    id: row.original._id,
                  });
                  setIsPopupOpen(true);
                }}
              >
                <ThumbIcon />
              </button>
            )}
            {(user.department === "admin" ||
              user.department === "ceo" ||
              user.department === "sm") && (
              <button
                data-tip
                data-for="cancel"
                className={style.icon}
                onClick={() => {
                  setModalType("cancel");
                  setTargetInventory({
                    fullName: row.original.queryContactName,
                    inventoryId: row.original.inventoryId._id,
                    id: row.original._id,
                  });
                  setIsPopupOpen(true);
                }}
              >
                <BanIcon />
              </button>
            )}
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      pageCount: data.length > 0 ? Math.ceil(pagination.totalCount) : 0,
      manualPagination: hasFilterData ? false : true,
      initialState: {
        pageIndex: pagination.queryPageIndex,
        pageSize: pagination.queryPageSize,
        hiddenColumns: [],
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );
  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      queryPageIndex: pageIndex,
    }));
  }, [pageIndex]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      queryPageSize: pageSize,
    }));
  }, [pageSize]);

  const reloadWindow = (e) => {
    e.preventDefault();
    window.location.reload();
    setPopup("");
  };

  const successClick = () => {
    if (modalType === "") {
      return;
    } else if (modalType === "approve") {
      sendApprove(user.token, user.userType, targetInventory)
        .then((res) => {
          setPopup(t(res.message));
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (modalType === "cancel") {
      sendApproveCancel(user.token, user.userType, targetInventory)
        .then((res) => {
          setPopup(t(res.message));
        })
        .catch((err) => {
          console.log(err);
        });
    }

    setModalType("");
    setTargetInventory({
      fullName: "",
      inventoryId: "",
      id: "",
    });
    setIsPopupOpen(false);
  };

  return (
    <>
      {popup ? <Popup closeClick={reloadWindow} text={popup} /> : null}
      {isPopupOpen && (
        <ApprovalPopUp
          type={modalType}
          closeClick={popUpClose}
          successClick={successClick}
          fullName={targetInventory.fullName}
        />
      )}
      <div className={style.approvallist}>
        <SubHeader approvalMenu approvalData={data} />
        <div className={style.content}>
          <SideFilter
            setApprovalData={setData}
            setHasFilterData={setHasFilterData}
            getPagination={pagination}
            setPagination={setPagination}
            setNewMethod={setNewMethod}
            setNewBody={setNewBody}
          />
          <Table
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            page={page}
            prepareRow={prepareRow}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
}

export default ApprovalList;
